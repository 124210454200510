export const config = {
  tokenKEY: "token",
  phoneKEY: "phone",
  backendTokenKEY: "Authorization",
};

export const CUSTOM_API_ROOT_PROD = "https://krupa-snab-backend.bis-apps.com/";
export const CUSTOM_API_ROOT_TEST = "https://krupa-snab-backend.bis-apps.com/";

// export const CUSTOM_API_ROOT_TEST = "https://krupa-snab-test-backend.bis-apps.com/";
