import { Button } from "antd";

export function column(t, getColumnSearchProps, driver = false,status=false) {
  const columns = [
    {
      title: t("mijoz"),
      dataIndex: "mijoz",
      key: "mijoz",
      ...getColumnSearchProps("mijoz"),
    },
    {
      title: t("numZakaz"),
      dataIndex: "docNum",
      key: "docNum",
      ...getColumnSearchProps("docNum"),
    },
    {
      title: t("date"),
      dataIndex: "sana",
      key: "sana",
      // ...getColumnSearchProps("sana"),
    },
    {
      title: t("priceSum"),
      dataIndex: "summa",
      key: "summa",
      // ...getColumnSearchProps("summa"),
    },
    {
      title: t("creator"),
      dataIndex: "yaratdi",
      key: "yaratdi",
      ...getColumnSearchProps("yaratdi"),
    },
  ];

  if (driver) {
    columns.push({
      title: t("driver"),
      dataIndex: "driver",
      key: "driver",
    });
  }
  if (status) {
    columns.push({
      title: t("status"),
      dataIndex: "docStatus",
      key: "status",
    });
  }

  return columns;
}

export function column5(t, getColumnSearchProps) {
  const columns = [
    {
      title: t("mijoz"),
      dataIndex: "mijoz",
      key: "mijoz",
      ...getColumnSearchProps("mijoz"),
    },
    {
      title: t("numZakaz"),
      dataIndex: "docNum",
      key: "docNum",
      ...getColumnSearchProps("docNum"),
    },
    {
      title: t("date"),
      dataIndex: "sana",
      key: "sana",
      // ...getColumnSearchProps("sana"),
    },
    {
      title: t("priceSum"),
      dataIndex: "summa",
      key: "summa",
      // ...getColumnSearchProps("summa"),
    },
    {
      title: t("creator"),
      dataIndex: "yaratdi",
      key: "yaratdi",
      ...getColumnSearchProps("yaratdi"),
    },
  ];

  return columns;
}

export function column2(
  t,
  getColumnSearchProps,
  driver = false,
  action = false,
) {
  const columns = [
    {
      title: t("mijoz"),
      dataIndex: "mijoz",
      key: "mijoz",
      ...getColumnSearchProps("mijoz"),
    },
    {
      title: t("numZakaz"),
      dataIndex: "docNum",
      key: "docNum",
      ...getColumnSearchProps("docNum"),
    },
    {
      title: t("date"),
      dataIndex: "sana",
      key: "sana",
      // ...getColumnSearchProps("sana"),
    },
    {
      title: t("priceSum"),
      dataIndex: "summa",
      key: "summa",
    },
    {
      title: t("creator"),
      dataIndex: "yaratdi",
      key: "yaratdi",
      ...getColumnSearchProps("yaratdi"),
    },
  ];

  if (driver) {
    columns.push({
      title: t("driver"),
      dataIndex: "driver",
      key: "driver",
    });
  }
  if (action) {
    columns.push({
      title: t("ship"),
      key: "action",
      render: () => (
        <Button className="bg-[#0A4D68] text-white">{t("ship")}</Button>
      ),
    });
  }

  return columns;
}

export function column3(
  t,
  getColumnSearchProps,
  driver = false,
  remove = false,
  onClick,
) {
  const columns = [
    {
      title: t("mijoz"),
      dataIndex: "mijoz",
      key: "mijoz",
      ...getColumnSearchProps("mijoz"),
    },
    {
      title: t("numZakaz"),
      dataIndex: "docNum",
      key: "docNum",
      ...getColumnSearchProps("docNum"),
    },
    {
      title: t("date"),
      dataIndex: "sana",
      key: "sana",
      // ...getColumnSearchProps("sana"),
    },
    {
      title: t("priceSum"),
      dataIndex: "summa",
      key: "summa",
    },
    {
      title: t("creator"),
      dataIndex: "yaratdi",
      key: "yaratdi",
      ...getColumnSearchProps("yaratdi"),
    },

    {
      title: t("Status"),
      dataIndex: "docStatus",
      key: "docStatus",
      ...getColumnSearchProps("docStatus"),

      // render: () => <Button className="bg-[#0A4D68] text-white">{t("remove")}</Button>,
    },
  ];

  if (driver) {
    columns.push({
      title: t("driver"),
      dataIndex: "driver",
      key: "driver",
    });
  }

  if (remove) {
    columns.push({
      title: t("remove"),
      dataIndex: "remove",
      key: "remove",
    });
  }

  return columns;
}
