import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from "react";
import { Table, Input, Button,Select } from "antd";
import Menubar from "../../../components/SalesManager/App";
import { http } from "../../../services/http";
import Navbar from "../../../components/navbar";
import CreateClientModal from "../../../components/SalesManager/createClientModal";
import { SearchOutlined } from "@ant-design/icons";
import debounce from "lodash.debounce";
import Highlighter from "react-highlight-words";
import { useTranslation } from "react-i18next";
import { formattedDataClient } from "../../../utils/document";
import ClientsModal from "../../../components/SalesManager/clientsModal";

function ClientsSales() {
  const [fdata, setFData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isClientModal, setIsClientModal] = useState(false);
  const [isCreateProductModalVisible, setIsCreateProductModalVisible] =
    useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const { t } = useTranslation();

  const [slp, setSlp] = useState([]);
  const [region, setRegion] = useState([]);
  const [cardCode, setCardCode] = useState([]);

  const [selectedSlp, setSelectedSlp] = useState("");
  const [selectedRegion, setSelectedRegion] = useState("");
  const [selectedName, setSelectedName] = useState("");

  const searchInputRef = useRef(null);

  const fetchData = useCallback(async (bpName, slpName,bpRegion) => {
    try {
      setLoading(true);

      const { data } = await http.get(
        `api/businesspartners/businesspartners?bpName=${bpName}&slpName=${slpName}&region=${bpRegion}`,
      );
      const newSlp = new Set(slp);
      const newRegion = new Set(region);
      const uniqueEntries = new Set();

      const formattedData = formattedDataClient(data);
      setFData(formattedData);

      data.filter((item) => {
        const key = `${item.salesPerson}-${item.region}`;

        if (uniqueEntries.has(key)) {
          return false;
        }

        uniqueEntries.add(key);
        newSlp.add(item.salesPerson);
        newRegion.add(item.region);

        return true;
      });

      setSlp([...newSlp]);
      setRegion([...newRegion]);

    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  }, []);

  const debouncedSearch = useMemo(
    () =>
      debounce((value, dataIndex, confirm) => {
        setSearchText(value);
        setSearchedColumn(dataIndex);
        confirm();
      }, 1000),
    [],
  );

  const handleSearch = (e, confirm, dataIndex) => {
    const value = e.target.value;
    setSelectedName(value);
    debouncedSearch(value, dataIndex, confirm);
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInputRef}
          placeholder={`${t("search")} ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
            handleSearch(e, confirm, dataIndex);
          }}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInputRef.current.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handleRowClick = (record) => {
    setIsClientModal(true);
    setCardCode(record.cardCode);
    console.log(record.cardCode);
  };

  useEffect(() => {
    fetchData(selectedName, selectedSlp,selectedRegion);
  }, [fetchData, selectedName, selectedSlp,selectedRegion]);

  const handleCreateProductClick = () => {
    setIsCreateProductModalVisible(true);
  };

  const closeCreateProductModal = () => {
    setIsCreateProductModalVisible(false);
    setFData([]);
    setSelectedName("");
    setSelectedSlp("");
    selectedRegion("")
    fetchData(selectedName, selectedSlp,selectedRegion);
  };

  const handleSelectChange = (value, key) => {
    switch (key) {
      case "region":
        value !== undefined
          ? setSelectedRegion(value)
          : setSelectedRegion("");
        break;

      default:
        break;
    }
  };

  const columns = [
    {
      title: t("region"),
      dataIndex: "region",
      key: "region",
      filterDropdown: () => (
        <Select
          allowClear
          style={{ width: 200 }}
          placeholder="Select Region"
          value={selectedRegion}
          onChange={(value) => handleSelectChange(value, "region")}
        >
          {region.map((desc) => (
            <Select.Option key={desc} value={desc}>
              {desc}
            </Select.Option>
          ))}
        </Select>
      ),
      onFilter: (value, record) => record.region === value,
    },
    {
      title: t("realizators"),
      dataIndex: "realizators",
      key: "realizators",
      ...getColumnSearchProps("realizators"),
    },
    {
      title: t("balance"),
      dataIndex: "balance",
      key: "balance",
      render: (text) => {
        const formattedText = new Intl.NumberFormat("fr-FR").format(text);
        return `${formattedText}`;
      },
    },
    {
      title: t("act"),
      key: "action",
      render: (text, record) => (
        <Button
          className="bg-[#0A4D68] text-white "
          onClick={() => handleRowClick(record)}
        >
          {t("act")}
        </Button>
      ),
    },
  ];

  return (
    <div className="flex h-full w-full overflow-y-auto">
      <Menubar />
      <div className="h-screen w-full overflow-y-auto">
        <Navbar />
        <h1 className="font-poppins ml-4 mt-10 text-xl font-bold text-black sm:ml-10 sm:mt-14 sm:text-2xl">
          {t("clientsG")}
        </h1>
        <div className="mt-10 w-full border-[1px] border-[#E8E8E8] sm:mt-14"></div>
        <div className="mt-6 flex w-full items-center justify-end gap-4 px-4 sm:mt-10 sm:gap-7 sm:px-10">
          <button
            onClick={handleCreateProductClick}
            className="h-[40px] w-[150px] rounded-2xl bg-[#0A4D68] text-white sm:w-[200px]"
          >
            {t("Add")}
          </button>
        </div>
        <div className="p-4" style={{ height: "calc(100vh - 64px)" }}>
          <Table
            columns={columns}
            dataSource={fdata}
            pagination={false}
            loading={loading}
            sticky={true}
            onRow={(record) => ({
              onClick: () => handleRowClick(record),
            })}
          />
        </div>
        <CreateClientModal
          visible={isCreateProductModalVisible}
          onClose={closeCreateProductModal}
        />
        <ClientsModal
          visible={isClientModal}
          onClose={() => setIsClientModal(false)}
          datas={cardCode}
        />
      </div>
    </div>
  );
}

export default ClientsSales;
