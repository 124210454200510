import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Menubar as MenuSales } from "../components/SalesManager/App";
import { Menubar as MenuWhs } from "../components/WhrManager/App";
import { http } from "../services/http";
import { SearchOutlined } from "@ant-design/icons";
import debounce from "lodash.debounce";
import Highlighter from "react-highlight-words";
import { useTranslation } from "react-i18next";
import Header from "../components/header";
import { column5 } from "../utils/columns";
import { aggregateDocuments } from "../utils/document";
import { useSelector } from "react-redux";
import ReturnedItemsModal from "../components/returnedItems-Modal";
import moment from "moment";
import { DatePicker, Input } from "antd";
import { get } from "lodash";

const { RangePicker } = DatePicker;
const today = new Date();
const day = ("0" + today.getDate()).slice(-2);
const month = ("0" + (today.getMonth() + 1)).slice(-2);
const year = today.getFullYear();
const formattedDate = `${year}-${month}-${day}`;

const ReturnedItems = () => {
  const { t } = useTranslation();
  const role = useSelector((state) => state.main.role);

  const [fdata, setFData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  // const [isRemove, setRemove] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");

  const [docNum, setDocNum] = useState("");
  const [cardName, setCardName] = useState("");
  const [slpName, setSlpName] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState(formattedDate);

  const [total, setTotal] = useState(0);

  const fetchData = useCallback(
    async (page, ScardName, SdocNum, SslpName, SstartDate, SendDate) => {
      try {
        let url = " ";
        setLoading(true);
        if (
          cardName === "" &&
          docNum === "" &&
          slpName === "" &&
          startDate === ""
        ) {
          url = `api/return/get-returns?cardName=${ScardName}&docNum=${SdocNum}&slpName=${SslpName}&startDate=${SstartDate}&endDate=${SendDate}&pageToken=${page}`;
        } else {
          url = `api/return/get-returns?cardName=${ScardName}&docNum=${SdocNum}&slpName=${SslpName}&startDate=${SstartDate}&endDate=${SendDate}`;
        }
        const { data } = await http.get(url);

        const formattedData = aggregateDocuments(data);

        const hasMore = formattedData.length === 10 ? true : false;
        setFData(formattedData);
        setHasMoreData(hasMore);
        setTotal(get(data, "total", 0));
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    },
    [cardName, docNum, startDate, endDate, slpName],
  );

  useEffect(() => {
    fetchData(currentPage, cardName, docNum, slpName, startDate, endDate);
  }, [fetchData, currentPage, cardName, docNum, slpName, startDate, endDate]);

  let searchInput = null;

  const handleDateRangeChange = (dates, confirm, dataIndex) => {
    debouncedSearch(dates, dataIndex, confirm);
  };

  const handleStartDate = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDate = (e) => {
    setEndDate(e.target.value);
  };

  const debouncedSearch = useMemo(
    () =>
      debounce((value, dataIndex, confirm) => {
        if (dataIndex === "mijoz") {
          if (value) {
            setCardName(value);
          } else {
            setCardName("");
          }
        } else if (dataIndex === "docNum") {
          if (value) {
            setDocNum(value);
          } else {
            setDocNum("");
          }
        } else if (dataIndex === "yaratdi") {
          if (value) {
            setSlpName(value.toUpperCase());
          } else {
            setSlpName("");
          }
        } else if (dataIndex === "sana") {
          if (value) {
            const formattedStartDate = new Date(value[0]).toLocaleString();
            const formattedEndDate = new Date(value[1]).toLocaleString();

            if (formattedStartDate && formattedEndDate) {
              setStartDate(moment(formattedStartDate).format("YYYY-MM-DD"));
              setEndDate(moment(formattedEndDate).format("YYYY-MM-DD"));
            }
          } else {
            setStartDate("");
            setEndDate("");
          }
        } else {
        }
        confirm();
      }, 2000),
    [fetchData, currentPage],
  );

  const getColumnSearchProps = (dataIndex) => {
    if (dataIndex === "sana") {
      return {
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
          <div style={{ padding: 8 }}>
            <RangePicker
              value={selectedKeys && selectedKeys[0] ? selectedKeys : []}
              onChange={(dates) => {
                setSelectedKeys(dates ? dates : []);
                handleDateRangeChange(dates, confirm, dataIndex);
              }}
              style={{ marginBottom: 8, display: "block" }}
              disabledDate={(current) =>
                current && current > moment().endOf("day")
              }
            />
          </div>
        ),
        filterIcon: (filtered) => (
          <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
        ),
        onFilter: (value, record) => {
          if (Array.isArray(value) && value.length === 2) {
            const [startDate, endDate] = value;
            const recordDate = moment(record[dataIndex]);
            return recordDate.isBetween(startDate, endDate, "[]");
          }
          return true;
        },
        render: (text) => text,
      };
    }
    return {
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              searchInput = node;
            }}
            placeholder={`${t("search")} ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              handleSearch(e, confirm, dataIndex);
            }}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      onFilter: (value, record) =>
        record[dataIndex]
          ? record[dataIndex]
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase())
          : "",
      onFilterDropdownOpenChange: (visible) => {
        if (visible) {
          setTimeout(() => searchInput.select(), 100);
        }
      },
      render: (text) =>
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ""}
          />
        ) : (
          text
        ),
    };
  };

  const handleSearch = (e, confirm, dataIndex) => {
    const value = e.target.value;
    debouncedSearch(value, dataIndex, confirm);
  };

  const handleNextPage = () => {
    if (hasMoreData) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const closeModal = () => {
    setIsModalVisible(false);
    fetchData(currentPage, cardName, docNum, slpName, startDate, endDate);
  };

  const handleRowClick = (record) => {
    setSelectedRowData(record);
    setIsModalVisible(true);
  };

  const columns = column5(t, getColumnSearchProps).map((col) => {
    if (col.dataIndex === "summa") {
      return {
        ...col,
        render: (text, record) => {
          const formatSum =
            record.docCur === "UZS"
              ? new Intl.NumberFormat("fr-FR").format(record.docTotalFC)
              : new Intl.NumberFormat("fr-FR").format(text);
          return `${formatSum} ${record.docCur}`;
        },
      };
    }
    if (col.dataIndex === "docNum") {
      return {
        ...col,
        title: t("numZakazWhs"),
      };
    }

    return col;
  });

  return (
    <div className="flex h-full w-full overflow-y-auto">
      {role === "Whsmanager" ? <MenuWhs /> : <MenuSales />}

      <Header
        title={"returned-items"}
        currentPage={currentPage}
        handlePreviousPage={handlePreviousPage}
        handleNextPage={handleNextPage}
        hasMoreData={hasMoreData}
        columns={columns}
        fdata={fdata}
        loading={loading}
        handleRowClick={handleRowClick}
        onStartDateChange={handleStartDate}
        onEndDateChange={handleEndDate}
        startDate={startDate}
        endDate={endDate}
        formattedDate={formattedDate}
        total={total}
      />
      <ReturnedItemsModal
        visible={isModalVisible}
        data={selectedRowData}
        onClose={closeModal}
      />
    </div>
  );
};

export default ReturnedItems;
