import React from "react";
import { Modal } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import ShipProduct from "../../pages/whrManager/sales/from-department/shipProduct";
import { useTranslation } from 'react-i18next';

const ShipProductModal = ({ visible, data, onClose }) => {
  const {t} =useTranslation()
  if (!data) return null;

  return (
    <Modal
      title={
        <h1 className="font-nunito text-xl font-extrabold text-[#000000]">
          {t("order")} № {data.docNum}
        </h1>
      }
      visible={visible}
      onCancel={onClose}
      footer={null}
      closeIcon={<CloseOutlined />}
      width="80%"
      style={{ height: "70%" }}
      bodyStyle={{ height: "calc(100% - 55px)", overflowY: "auto" }}
    >
      <ShipProduct datas={data} onClose={onClose} visible={visible}/>
    </Modal>
  );
};

export default ShipProductModal;
