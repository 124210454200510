import { Modal, Input, Button, Table, Select } from "antd";
import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { CloseOutlined } from "@ant-design/icons";
import RowDataShipmentEdit from "./row-data-edit";

const RowDataShipmentModal = ({ visible, data, onClose }) => {
  const { t } = useTranslation();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const pdfRef = useRef(null);

  const [isCommentModalVisible, setIsCommentModalVisible] = useState(false);
  const [selectedComments, setSelectedComments] = useState("");

  const handleSelectClick = () => {
    setSelectedComments(data.comments);
    setIsCommentModalVisible(true);
  };

  const handleModalOk = () => {
    setIsCommentModalVisible(false);
  };

  const handleModalCancel = () => {
    setIsCommentModalVisible(false);
  };

  if (!data) return null;

  const sum =
    data.docCur === "UZS"
      ? new Intl.NumberFormat("fr-FR").format(data.docTotalFC)
      : new Intl.NumberFormat("fr-FR").format(data.summa);

  const columns = [
    {
      title: t("item"),
      dataIndex: "maxsulot",
      key: "maxsulot",
    },
    {
      title: t("quantity"),
      dataIndex: "quantity",
      key: "quantity",
      render: (text, record) => {
        const formattedText = new Intl.NumberFormat("fr-FR").format(text);
        return `${formattedText} `;
      },
    },
    {
      title: t("measureUnit"),
      dataIndex: "measureUnit",
      key: "measureUnit",
    },
    {
      title: t("price"),
      dataIndex: "price",
      key: "price",
      render: (text, record) => {
        const formattedText = new Intl.NumberFormat("fr-FR").format(text);
        return `${formattedText} ${record.currency}`;
      },
    },
    {
      title: t("inventoryQuantity"),
      dataIndex: "inventoryQuantity",
      key: "inventoryQuantity",
    },
    {
      title: t("ugpName"),
      dataIndex: "ugpName",
      key: "ugpName",
    },

    {
      title: t("totalMiqdor"),
      dataIndex: "lineTotal",
      key: "lineTotal",
      render: (text, record) => {
        const formatSum =
          record.currency === "UZS"
            ? new Intl.NumberFormat("fr-FR").format(record.totalFrgn)
            : new Intl.NumberFormat("fr-FR").format(text);
        return `${formatSum} ${record.currency}`;
      },
    },
    {
      title: t("whs"),
      dataIndex: "whsName",
      key: "whsName",
    },
  ];

  const copyTo = () => {
    setIsModalVisible(true);
  };

  const tableData = data.maxsulot.map((item, index) => ({
    key: index,
    maxsulot: item,
    quantity: data.quantity[index],
    measureUnit: data.measureUnit[index],
    price: data.price[index],
    inventoryQuantity: data.inventoryQuantity[index],
    lineTotal: data.lineTotal[index],
    whsName: data.whsName[index],
    ugpName: data.ugpName[index],
    currency: data.currency[index],
    totalFrgn: data.totalFrgn[index],
  }));

  return (
    <Modal
      title={
        <h1 className="font-nunito text-xl font-extrabold text-[#000000]">
          {t("order")} № {data.docNum}
        </h1>
      }
      visible={visible}
      onCancel={onClose}
      footer={null}
      closeIcon={<CloseOutlined />}
      width="85%"
      style={{ height: "85%" }}
      bodyStyle={{ height: "calc(100% - 55px)", overflowY: "auto" }}
    >
      <div className="w-full px-14" ref={pdfRef}>
        <div className="w-full">
          <div className="mt-10 flex items-center justify-between">
            <div className="flex flex-col gap-3">
              <p className="font-nunito">{t("mijoz")}</p>
              <Input
                type="text"
                value={data.mijoz}
                className="flex h-12 w-[250px] items-center justify-center"
                style={{ color: "black" }}
                disabled
              />
            </div>
            <div className="flex flex-col gap-3">
              <p className="font-nunito">{t("date")} </p>
              <Input value={data.sana} style={{ color: "black" }} disabled />
            </div>
          </div>
          <Table
            columns={columns}
            dataSource={tableData}
            pagination={false}
            className="border-1 mt-12 border-black"
          />

          <div className="mt-5 flex items-center justify-end gap-5">
            <div className="flex flex-col gap-3">
              <p>{t("docTotalQuantity")}</p>
              <Input
                type="text"
                value={`${data.docTotalQuantity} кг`}
                className="flex h-10 w-full items-center justify-center border-[1px] border-[#D0D0D0] active:border-[#D0D0D0] sm:w-[200px]"
                disabled
                style={{ color: "black" }}
              />
            </div>

            <div className="flex flex-col gap-3">
              <p>{t("totalPrice")}</p>
              <Input
                type="text"
                value={`${sum} ${data.docCur}`}
                style={{ color: "black" }}
                className="flex h-10 w-full items-center justify-center border-[1px] border-[#D0D0D0] active:border-[#D0D0D0] sm:w-[200px]"
                disabled
              />
            </div>
          </div>

          <div className="mt-10 flex items-center justify-between">
            <div className="flex flex-col gap-3">
              <p>{t("comments")}</p>
              <Select
                value={data.comments}
                className="flex h-12 w-full items-center justify-center sm:w-[250px]"
                style={{ color: "black" }}
                disabled
                onClick={handleSelectClick}
              />
              <Modal
                title={t("comments")}
                visible={isCommentModalVisible}
                onOk={handleModalOk}
                onCancel={handleModalCancel}
              >
                <p>{selectedComments}</p>
              </Modal>
            </div>

            <div className="flex flex-col gap-5">
              <div className="flex flex-col gap-3">
                <p>{t("responsiblePerson")}</p>
                <Input
                  type="text"
                  value={data.yaratdi}
                  disabled
                  style={{ color: "black" }}
                  className="flex h-10 w-[200px] items-center justify-center border-[1px] border-[#D0D0D0]"
                />
              </div>
            </div>
          </div>

          <div className="flex h-[100%] w-[100%] flex-col justify-center">
            <div className="mt-20 flex items-center justify-between">
              <Button
                onClick={copyTo}
                className="h-12 w-[300px] rounded-3xl bg-[#0A4D68] text-white"
              >
                {t("edit")}
              </Button>
            </div>
          </div>
          <RowDataShipmentEdit
            visible={isModalVisible}
            data={data}
            onClose={() => setIsModalVisible(false)}
          />
        </div>
      </div>
    </Modal>
  );
};

export default RowDataShipmentModal;
