import React, { useState, useEffect, useCallback } from "react";
import { Table, Input, Select,Button } from "antd";
import { Menubar as MenuSales } from "../components/SalesManager/App";
import { Menubar as MenuWhs } from "../components/WhrManager/App";
import { http } from "../services/http";
import { useTranslation } from "react-i18next";
import { formattedShippedReports, calculateTotal } from "../utils/document";
import Navbar from "../components/navbar";
import { useSelector } from "react-redux";

const { Option } = Select;

const ShippedReports = () => {
  const today = new Date();
  const day = ("0" + today.getDate()).slice(-2);
  const month = ("0" + (today.getMonth() + 1)).slice(-2);
  const year = today.getFullYear();
  const formattedDate = `${year}-${month}-${day}`;
  const { t } = useTranslation();
  const [fdata, setFData] = useState([]);
  const [loading, setLoading] = useState(true);
  const role = useSelector((state) => state.main.role);
  const [startDate, setStartDate] = useState("2024-01-01");
  const [endDate, setEndDate] = useState(formattedDate);
  const [totals, setTotals] = useState({});

  const [descriptions, setDescriptions] = useState([]);
  const [itemGroups, setItemGroups] = useState([]);

  const [selectedDescription, setSelectedDescription] = useState("");
  const [selectedItemGroup, setSelectedItemGroup] = useState("");
  const [excelLoading, setExcelLoading] = useState(false);

  const fetchData = useCallback(
    async (selectedDescription, selectedItemGroup) => {
      setLoading(true);
      try {
        const { data } = await http.get(
          `api/deliverynotesdata/delivereditems/by-filter?description=${selectedDescription}&itemGroupName=${selectedItemGroup}&startDate=${startDate}&endDate=${endDate}`,
        );

        const newDescriptions = new Set(descriptions);
        const newItemGroups = new Set(itemGroups);
        const uniqueEntries = new Set();

        data.filter((item) => {
          const key = `${item.dscription}-${item.itmsGrpNam}-${item.u_typeOfTransport}-${item.u_tracking}-${item.u_numberOfTransport}-${item.docDate}`;

          if (uniqueEntries.has(key)) {
            return false;
          }
          uniqueEntries.add(key);
          newDescriptions.add(item.dscription);
          newItemGroups.add(item.itmsGrpNam);

          return true;
        });

        setDescriptions([...newDescriptions]);
        setItemGroups([...newItemGroups]);

        const formattedData = formattedShippedReports(data);
        setFData(formattedData);
        setTotals(calculateTotal(data));
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    },
    [startDate, endDate],
  );

  const handleExportExcel = async () => {
    setExcelLoading(true)
    try {
      const res = await http.post(
        "api/deliverynotesdata/export-to-excel",
        {},
        {
          headers: {
            "Content-Type":
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          },
          responseType: "blob", // Ensure the response is treated as a blob
        },
      );

      const url = window.URL.createObjectURL(
        new Blob([res.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        }),
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "yuklangan-buyurtmalar-hisobi.xlsx"); // Define the download file name
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {}
    setExcelLoading(false)
  };

  useEffect(() => {
    fetchData(selectedDescription,selectedItemGroup);
  }, [fetchData, startDate, endDate,selectedDescription,selectedItemGroup]);

  const handleSelectChange = (value, key) => {
    switch (key) {
      case "description":
        value !== undefined
          ? setSelectedDescription(value)
          : setSelectedDescription("");
        break;
      case "itemGroup":
        value !== undefined
          ? setSelectedItemGroup(value)
          : setSelectedItemGroup("");

        break;
      default:
        break;
    }
  };

  const columns = [
    {
      title: t("dscription"),
      dataIndex: "dscription",
      key: "dscription",
      width: 300,
      filterDropdown: () => (
        <Select
          allowClear
          style={{ width: 350 }}
          placeholder="Select Description"
          value={selectedDescription}
          onChange={(value) => handleSelectChange(value, "description")}
        >
          {descriptions.map((desc) => (
            <Option key={desc} value={desc}>
              {desc}
            </Option>
          ))}
        </Select>
      ),
      onFilter: (value, record) => record.dscription === value,
    },
    {
      title: t("itmsGrpNam"),
      dataIndex: "itmsGrpNam",
      key: "itmsGrpNam",
      width: 150,

      filterDropdown: () => (
        <Select
          allowClear
          style={{ width: 200 }}
          placeholder="Select Item Group"
          value={selectedItemGroup}
          onChange={(value) => handleSelectChange(value, "itemGroup")}
        >
          {itemGroups.map((group) => (
            <Option key={group} value={group}>
              {group}
            </Option>
          ))}
        </Select>
      ),
      onFilter: (value, record) => record.itmsGrpNam === value,
    },

    {
      title: t("quantity"),
      dataIndex: "totalQuantity",
      key: "totalQuantity",
      render: (text) => {
        const formattedText = new Intl.NumberFormat("fr-FR").format(text);
        return `${formattedText} `;
      },
    },
    {
      title: t("unitMsr"),
      dataIndex: "unitMsr",
      key: "unitMsr",
    },

    {
      title: t("inventoryQuantity"),
      dataIndex: "totalInvQty",
      key: "totalInvQty",
    },

    {
      title: t("ugpName"),
      dataIndex: "ugpName",
      key: "ugpName",
    },
  ];

  return (
    <div className="flex h-full w-full overflow-y-auto">
      {role === "Whsmanager" ? <MenuWhs /> : <MenuSales />}
      <div className="h-screen w-full overflow-y-auto">
        <Navbar />
        <h1 className="font-poppins ml-4 mt-10 text-xl font-bold text-black sm:ml-10 sm:mt-14 sm:text-2xl">
          {t("shippedReports")}
        </h1>
        <div className="mt-10 w-full border-[1px] border-[#E8E8E8] sm:mt-14"></div>
        <div className="ml-10 mt-5 flex gap-5">
          <div className="flex flex-col gap-3">
            <p>{t("startDate")}</p>
            <Input
              type="date"
              value={startDate}
              className="w-[150px]"
              onChange={(e) => {
                setStartDate(e.target.value);
              }}
              max={endDate}
            />
          </div>
          <div className="flex flex-col gap-3">
            <p>{t("endDate")}</p>
            <Input
              type="date"
              value={endDate}
              className="w-[150px]"
              onChange={(e) => {
                setEndDate(e.target.value);
              }}
              min={startDate}
              max={formattedDate}
            />
          </div>
        </div>

        <Button
            onClick={handleExportExcel}
            loading={excelLoading}
            className="h-12 w-full rounded-3xl bg-[#0A4D68] text-white sm:w-[200px] mt-10 ml-5"
          >
            Export to Excel
          </Button>

        <Table
          columns={columns}
          dataSource={fdata}
          pagination={false}
          className="mt-6 w-full sm:mt-10"
          rowKey="zakaz"
          sticky={true}
          loading={loading}
          summary={() => (
            <Table.Summary.Row>
              <Table.Summary.Cell
                index={0}
                className="font-bold text-black"
              ></Table.Summary.Cell>
              <Table.Summary.Cell
                index={1}
                colSpan={1}
                className="font-bold text-black"
              >
                {t("total")}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={2} className="font-bold text-black">
              </Table.Summary.Cell>
              <Table.Summary.Cell
                index={3}
                className="font-bold text-black"
              ></Table.Summary.Cell>
              <Table.Summary.Cell index={4} className="font-bold text-black">
                {new Intl.NumberFormat("fr-FR").format(totals.totalInvQty)}
              </Table.Summary.Cell>
              <Table.Summary.Cell
                index={5}
                className="font-bold text-black"
              ></Table.Summary.Cell>
            </Table.Summary.Row>
          )}
        />
      </div>
    </div>
  );
};

export default ShippedReports;