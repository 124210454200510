import React, { useState, useCallback, useEffect } from "react";
import { Modal, Input, Table, Button, message, Select } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { http } from "../../services/http";

const calculateDifferences = (openQty, quantity) => {
  return openQty.map((openQtyValue, index) => {
    return quantity[index] - openQtyValue;
  });
};

const RowDataFinish = ({ visible, data, onClose }) => {
  const { t } = useTranslation();
  const today = new Date();
  const day = ("0" + today.getDate()).slice(-2);
  const month = ("0" + (today.getMonth() + 1)).slice(-2);
  const year = today.getFullYear();
  const formattedDate = `${year}-${month}-${day}`;

  const fiveDaysAgo = new Date();
  fiveDaysAgo.setDate(today.getDate() - 5);
  const dayFiveAgo = ("0" + fiveDaysAgo.getDate()).slice(-2);
  const monthFiveAgo = ("0" + (fiveDaysAgo.getMonth() + 1)).slice(-2);
  const yearFiveAgo = fiveDaysAgo.getFullYear();
  const minDate = `${yearFiveAgo}-${monthFiveAgo}-${dayFiveAgo}`;

  const employeeId = useSelector((state) => state.main.employeeId);
  const initialData = data || {
    price: [],
    maxsulot: [],
    quantity: [],
    measureUnit: [],
    inventoryQuantity: [],
    lineTotal: [],
    docEntry: null,
    lineNum: [],
    itemCode: [],
    whsCode: [],
    whsName: [],
    cardCode: "",
    openInvQty: [],
    summa: 0,
    u_Driver: 0,
    u_Father_DocNum: "",
    salesPersonCode: null,
    driver: "",
    mijoz: "",
    sana: "",
    comments: "",
    yaratdi: "",
    currency: [],
    docCur: "",
    openQty: [],
    totalFrgn: [],
  };

  const [uQuantity, setUQuantity] = useState(
    calculateDifferences(initialData.openQty, initialData.quantity),
  );
  const [totalPrice, setTotalPrice] = useState(data?.summa || 0);
  const [docTotalFC, setDocTotalFC] = useState(data?.docTotalFC || 0);
  const [date, setDate] = useState(formattedDate);

  const sum =
    initialData.docCur === "UZS"
      ? new Intl.NumberFormat("fr-FR").format(docTotalFC)
      : new Intl.NumberFormat("fr-FR").format(totalPrice);

  useEffect(() => {
    if (data && data.openQty && data.quantity) {
      const differences = calculateDifferences(data?.openQty, data?.quantity);
      setUQuantity(differences);
    }
  }, [data]);

  const [prices, setPrices] = useState(initialData.price);

  const [loading, setLoading] = useState(false);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedComments, setSelectedComments] = useState("");

  const handleSelectClick = () => {
    setSelectedComments(data?.comments);
    setIsModalVisible(true);
  };

  const handleModalOk = () => {
    setIsModalVisible(false);
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    if (data) {
      setPrices(data?.price || []);
      setTotalPrice(data?.summa || 0);
      setDocTotalFC(data?.docTotalFC || 0);
    }
  }, [data]);

  const handlePriceChange = useCallback(
    (e, index) => {
      const newPrices = [...prices];
      newPrices[index] = e.target.value;
      setPrices(newPrices);
      setTableData((prevTableData) =>
        prevTableData.map((row, idx) =>
          idx === index
            ? {
                ...row,
                price: newPrices[index],
                lineTotal: newPrices[index] * row.openQty,
                totalFrgn: newPrices[index] * row.openQty,
              }
            : row,
        ),
      );

      // Calculate new total price
      const newTotalPrice = newPrices.reduce((acc, price, idx) => {
        return acc + price * data?.openQty[idx];
      }, 0);
      setTotalPrice(newTotalPrice);
      setDocTotalFC(newTotalPrice);
    },
    [prices, data],
  );

  const [tableData, setTableData] = useState(() =>
    initialData.maxsulot.map((item, index) => ({
      key: index,
      maxsulot: item,
      quantity: data.quantity[index],
      measureUnit: data.measureUnit[index],
      price: data.price[index],
      uQuantity: uQuantity[index],
      lineTotal: data.price[index] * data.openQty[index],
      openQty: data.openQty[index],
      openInvQty: data.openInvQty[index],
      ugpName: data.ugpName[index],
      whsName: data.whsName[index],
      currency: data.currency[index],
      totalFrgn: data.price[index] * data.openQty[index],
    })),
  );

  useEffect(() => {
    if (data) {
      setTableData(
        initialData.maxsulot.map((item, index) => ({
          key: index,
          maxsulot: item,
          quantity: data.quantity[index],
          measureUnit: data.measureUnit[index],
          price: data.price[index],
          openInvQty: data.openInvQty[index],
          uQuantity: uQuantity[index],
          lineTotal: data.price[index] * data.openQty[index],
          openQty: data.openQty[index],
          ugpName: data.ugpName[index],
          whsName: data.whsName[index],
          currency: data.currency[index],
          totalFrgn: data.price[index] * data.openQty[index],
        })),
      );
    }
  }, [data, uQuantity]);

  const columns = [
    {
      title: t("item"),
      dataIndex: "maxsulot",
      key: "maxsulot",
    },
    {
      title: t("quantityShipped"),
      dataIndex: "quantity",
      key: "quantity",
      render: (text) => {
        const formattedText = new Intl.NumberFormat("fr-FR").format(text);
        return `${formattedText}`;
      },
    },
    {
      title: t("quantityRemoved"),
      dataIndex: "uQuantity",
      key: "uQuantity",
      render: (text) => {
        const formattedText = new Intl.NumberFormat("fr-FR").format(text);
        return `${formattedText}`;
      },
    },
    {
      title: t("openQty"),
      dataIndex: "openQty",
      key: "openQty",
      render: (text) => {
        const formattedText = new Intl.NumberFormat("fr-FR").format(text);
        return `${formattedText}`;
      },
    },
    {
      title: t("unitMsr"),
      dataIndex: "measureUnit",
      key: "measureUnit",
    },
    {
      title: t("price"),
      dataIndex: "price",
      key: "price",
      render: (text, record, index) => (
        <Input
          type="number"
          value={prices[index]}
          className="w-[90px]"
          onChange={(e) => handlePriceChange(e, index)}
        />
      ),
    },
    {
      title: t("inventoryQuantity"),
      dataIndex: "openInvQty",
      key: "openInvQty",
    },
    {
      title: t("ugpName"),
      dataIndex: "ugpName",
      key: "ugpName",
    },
    {
      title: t("totalMiqdor"),
      dataIndex: "lineTotal",
      key: "lineTotal",
      render: (text, record) => {
        const formatSum =
          record.currency === "UZS"
            ? new Intl.NumberFormat("fr-FR").format(record.totalFrgn)
            : new Intl.NumberFormat("fr-FR").format(text);
        return `${formatSum} ${record.currency}`;
      },
    },
    {
      title: t("whs"),
      dataIndex: "whsName",
      key: "whsName",
    },
  ];

  const postData = async () => {
    setLoading(true);
    try {
      const documentLines = initialData.maxsulot
        .map((item, index) => {
          if (initialData.openQty[index] !== 0) {
            return {
              baseEntry: initialData.docEntry,
              baseLine: initialData.lineNum[index],
              itemCode: initialData.itemCode[index],
              quantity: initialData.openQty[index],
              warehouseCode: initialData.whsCode[index],
              price: Number(prices[index]),
              currency: initialData.currency[index],
            };
          } else {
            return null;
          }
        })
        .filter((line) => line !== null);
      await http.post("api/yakunlangansotuvlar", {
        cardCode: String(initialData.cardCode),
        // docTotal: initialData.summa,
        u_Driver: data.u_Driver,
        u_Father_DocNum: data.u_Father_DocNum,
        u_Responsible_Person: employeeId,
        salesPersonCode: initialData.salesPersonCode,
        documentsOwner: employeeId,
        docCurrency: initialData.docCur,
        docDueDate: date,
        docDate: date,

        documentLines: documentLines,
      });
      message.success(t("sucCreated"));

      setTimeout(() => {
        onClose();
      }, 800);
    } catch (error) {
      message.error(t("errCreation") + error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      title={
        <h1 className="font-nunito text-xl font-extrabold text-[#000000]">
          {t("order")} № {initialData.docNum}
        </h1>
      }
      visible={visible}
      onCancel={onClose}
      footer={null}
      closeIcon={<CloseOutlined />}
      width="80%"
      bodyStyle={{ maxHeight: "80vh", overflowY: "auto" }}
    >
      <div className="w-full px-4 sm:px-14">
        <div className="w-full">
          <div className="mt-4 flex flex-col justify-between sm:mt-10 sm:flex-row">
            <div className="flex flex-col gap-3">
              <p className="font-nunito">{t("mijoz")}</p>
              <Input
                type="text"
                value={initialData.mijoz}
                style={{ color: "black" }}
                className="flex h-12 w-full items-center justify-center sm:w-[250px]"
                disabled
              />
            </div>
            <div className="mt-4 flex flex-col gap-3 sm:mt-0">
              <p className="font-nunito">{t("date")}</p>
              <Input
                type="date"
                value={date}
                style={{ color: "black" }}
                onChange={(e) => {
                  setDate(e.target.value);
                }}
                max={formattedDate}
                min={minDate}
              />
            </div>
          </div>
          <Table
            columns={columns}
            dataSource={tableData}
            pagination={false}
            className="border-1 mt-4 w-full border-black sm:mt-12"
            scroll={{ x: "100%" }}
          />
          <div className="mt-5 flex items-center justify-end gap-5">
            <div className="flex flex-col gap-3">
              <p>{t("docTotalQuantity")}</p>
              <Input
                type="text"
                value={`${data?.docTotalQuantity} кг`}
                style={{ color: "black" }}
                className="flex h-10 w-full items-center justify-center border-[1px] border-[#D0D0D0] active:border-[#D0D0D0] sm:w-[200px]"
                disabled
              />
            </div>

            <div className="flex flex-col gap-3">
              <p>{t("totalPrice")}</p>
              <Input
                type="text"
                value={`${sum} ${initialData.docCur}`}
                style={{ color: "black" }}
                className="flex h-10 w-full items-center justify-center border-[1px] border-[#D0D0D0] active:border-[#D0D0D0] sm:w-[200px]"
                disabled
              />
            </div>
          </div>

          <div className="mt-4 flex flex-col justify-between sm:mt-10 sm:flex-row">
            <div className="flex flex-col gap-3">
              <p>{t("comments")}</p>
              <Select
                value={initialData.comments}
                className="flex h-12 w-full items-center justify-center sm:w-[250px]"
                style={{ color: "black" }}
                disabled
                onClick={handleSelectClick}
              />
              <Modal
                title={t("comments")}
                visible={isModalVisible}
                onOk={handleModalOk}
                onCancel={handleModalCancel}
              >
                <p>{selectedComments}</p>
              </Modal>
            </div>

            <div className="flex flex-col gap-3">
              <p>{t("driver")}</p>
              <Input
                value={initialData.driver}
                className="flex h-12 w-full items-center justify-center sm:w-[200px]"
                style={{ color: "black" }}
                disabled
              />
            </div>

            <div className="mt-4 flex flex-col gap-3 sm:mt-0">
              <p>{t("responsiblePerson")}</p>
              <Input
                type="text"
                value={initialData.yaratdi}
                disabled
                style={{ color: "black" }}
                className="flex h-12 w-full items-center justify-center border-[1px] border-[#D0D0D0] sm:w-[150px]"
              />
            </div>
          </div>

          <div className="mt-14">
            <Button
              onClick={postData}
              className="h-12 w-[300px] rounded-3xl bg-[#0A4D68] text-white"
              loading={loading}
              disabled={loading}
            >
              {t("finishSale")}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default RowDataFinish;
