import React from "react";
import { Table } from "antd";
import { useTranslation } from "react-i18next";
import { http } from "../../services/http";

const TopProductsTable = () => {
  const { t } = useTranslation();
  const [topProducts, setTopProducts] = React.useState([]);

  React.useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const { data } = await http.get("api/dashboard/get-item-stats");
      const { itemStats } = data;

      const mappedItems = itemStats.map((item, index) => {
        return {
          id: index + 1,
          name: item.Dscription,
          quantity: item.itemsCount,
          color: "#1890ff",
        };
      });

      setTopProducts(mappedItems.slice(0, 5));

    } catch (err) {
      console.log(err);
    }
  };

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
      render: (text) => (text < 10 ? `0${text}` : text),
    },
    {
      title: t("item"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: t("quantity"),
      dataIndex: "quantity",
      key: "quantity",
    },
  ];



  return (
    <div className="mx-auto mt-8 max-w-4xl rounded-lg bg-white p-4 shadow-md">
      <h2 className="mb-4 text-xl font-bold">{t("topProducts")}</h2>
      <Table
        dataSource={topProducts}
        columns={columns}
        pagination={false}
        rowKey="id"
      />
    </div>
  );
};

export default TopProductsTable;
