import React, { useState, useEffect } from "react";
import { Select, DatePicker, Input, message, Spin, Button } from "antd";
import { http } from "../../../services/http";
import moment from "moment";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const { Option } = Select;

function Buy({ onClose, visible }) {
  const todayDate = moment().format("DD/MM/YYYY");
  const employeeId = useSelector((state) => state.main.employeeId);
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [currency, setCurrency] = useState();

  const [loadingData, setLoadingData] = useState({
    fdata: false,
    pdata: false,
    wdata: false,
    ddata: false,
  });

  const [data, setData] = useState({
    fdata: [],
    pdata: [],
    wdata: [],
    ddata: [],
    cardCode: "",
    warehouseCode: "",
    driverCode: "",
    items: [{ itemCode: "", quantity: "", price: "", manualPrice: false }],
  });

  const {
    fdata,
    pdata,
    wdata,
    ddata,
    cardCode,
    warehouseCode,
    items,
    driverCode,
  } = data;

  useEffect(() => {
    if (visible) {
      const fetchAllData = async () => {
        setLoadingData({ fdata: true, pdata: true, wdata: true, ddata: true });
        await Promise.all([
          fetchData(),
          fetchPartner(),
          fetchWarehouse(),
          fetchDrivers(),
        ]);
        setLoadingData({
          fdata: false,
          pdata: false,
          wdata: false,
          ddata: false,
        });
      };
      fetchAllData();
      resetForm();
    }
  }, [visible]);

  const fetchData = async () => {
    try {
      const { data } = await http.get(`api/items`);
      const mappedItems = data.map((item) => ({
        code: item.itemCode,
        name: item.itemName,
        price: item.itemPrice.price,
        currency: item.itemPrice.currency,
        priceList: item.itemPrice.priceList,
      }));
      setData((prevState) => ({
        ...prevState,
        fdata: mappedItems,
      }));
    } catch (error) {
      console.log(error);
    }
  };

  const fetchPartner = async () => {
    try {
      const { data } = await http.get(`api/businesspartners/suppliers`);
      const mappedItems = data.map((item) => ({
        code: item.cardCode,
        name: item.cardName,
      }));
      setData((prevState) => ({
        ...prevState,
        pdata: mappedItems,
      }));
    } catch (err) {
      console.log(err);
    }
  };

  const fetchWarehouse = async () => {
    try {
      const { data } = await http.get(`api/warehouses`);
      const mappedItems = data.map((item) => ({
        code: item.warehouseCode,
        name: item.warehouseName,
      }));
      setData((prevState) => ({
        ...prevState,
        wdata: mappedItems,
      }));
    } catch (err) {
      console.log(err);
    }
  };

  const fetchDrivers = async () => {
    try {
      const { data } = await http.get(`api/drivers`);

      const formattedData = data.map((item) => ({
        name: `${item.firstName} ${item.lastName}`,
        value: item.employeeID,
      }));

      setData((prevState) => ({
        ...prevState,
        ddata: formattedData,
      }));
    } catch (err) {
      console.log(err);
    }
  };

  const handleSelectChange = (value, index) => {
    const selectedItem = fdata.find((item) => item.name === value);
    const updatedItems = [...items];
    updatedItems[index] = {
      ...updatedItems[index],
      itemCode: selectedItem.code,
      price: updatedItems[index].manualPrice
        ? updatedItems[index].price
        : selectedItem.price,
      currency: currency,
      manualPrice: updatedItems[index].manualPrice,
    };
    setData((prevState) => ({
      ...prevState,
      items: updatedItems,
    }));
  };

  const handleMijozChange = (value) => {
    const selectedItem = pdata.find((item) => item.name === value);
    setData((prevState) => ({
      ...prevState,
      cardCode: selectedItem.code,
    }));
  };

  const handleCurrencyChange = (value) => {
    setCurrency(value);
    message.info(t("inputPrice", { value }));
    const newItems = items.map((item) => ({
      ...item,
      price: "",
      manualPrice: "",
      currency: value,
    }));

    setData((prevState) => ({
      ...prevState,
      items: newItems,
    }));
  };

  const handleOmborChange = (value) => {
    const selectedItem = wdata.find((item) => item.name === value);
    setData((prevState) => ({
      ...prevState,
      warehouseCode: selectedItem.code,
    }));
  };

  const handleInputChange = (e, index, field) => {
    const updatedItems = [...items];
    updatedItems[index] = {
      ...updatedItems[index],
      [field]: e.target.value,
      manualPrice: field === "price" ? true : updatedItems[index].manualPrice,
    };
    setData((prevState) => ({
      ...prevState,
      items: updatedItems,
    }));
  };

  const handleDriverChange = (value) => {
    const selectedItem = ddata.find((item) => item.name === value);
    setData((prevState) => ({
      ...prevState,
      driverCode: selectedItem.value,
    }));
  };

  const removeItem = (index) => {
    const updatedItems = items.filter((_, i) => i !== index);
    setData((prevState) => ({
      ...prevState,
      items: updatedItems,
    }));
  };

  const addItem = () => {
    setData((prevState) => ({
      ...prevState,
      items: [
        ...prevState.items,
        {
          itemCode: "",
          quantity: "",
          price: "",
          currency: "",
          manualPrice: false,
        },
      ],
    }));
  };

  const postData = async () => {
    if (
      cardCode &&
      currency &&
      driverCode &&
      items.every((item) => item.itemCode && item.quantity)
    ) {
      setLoading(true);
      try {
        await http.post("api/purchaseinvoices", {
          cardCode: cardCode,
          documentsOwner: employeeId,
          u_Driver:driverCode,
          documentLines: items.map((item) => ({
            itemCode: item.itemCode,
            quantity: item.quantity,
            warehouseCode,
            price: item.price,
            currency: currency,
          })),
        });
        message.success(t("sucCreated"));

        setTimeout(() => {
          resetForm();
          onClose();
          // window.location.reload();
        }, 800);
      } catch (error) {
        message.error(t("errCreation") + error.message);
        console.log("Error:", error);
      } finally {
        setLoading(false);
      }
    } else {
      message.error(t("fillForm"));
    }
  };

  const resetForm = () => {
    setData((prevState) => ({
      ...prevState,
      cardCode: "",
      warehouseCode: "",
      driverCode: "",
      items: [{ itemCode: "", quantity: "", price: "", manualPrice: false }],
    }));
  };

  return (
    <div className="w-full px-4 sm:px-14">
      <div className="w-full">
        <div className="mt-10 flex flex-col items-center justify-between sm:flex-row">
          <div className="flex w-full flex-col gap-3 sm:w-auto">
            <p className="font-nunito">{t("supplier")}</p>
            <Spin spinning={loadingData.pdata}>
              <Select
                value={
                  cardCode
                    ? pdata.find((item) => item.code === cardCode)?.name
                    : undefined
                }
                placeholder={t("choose")}
                onChange={handleMijozChange}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
                className="h-12 w-full items-center justify-center sm:w-[250px]"
              >
                {pdata
                  .filter((item) => item.name !== null)
                  .map((item) => (
                    <Select.Option key={item.code} value={item.name}>
                      {item.name}
                    </Select.Option>
                  ))}
              </Select>
            </Spin>
          </div>

          <div className="mt-4 flex w-full flex-col gap-3 sm:mt-0 sm:w-auto">
            <p className="font-nunito">{t("date")}</p>
            <DatePicker
              defaultValue={moment(todayDate, "DD/MM/YYYY")}
              disabled
              className="w-full sm:w-auto"
            />
          </div>

          <div className="flex w-full flex-col gap-3 sm:w-auto">
            <p className="font-nunito">{t("currency")}</p>
            <Select
              placeholder={t("choose")}
              showSearch
              optionFilterProp="children"
              onChange={handleCurrencyChange}
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
              className="flex h-12 w-full items-center justify-center sm:w-[250px]"
            >
              <Option key="USD" value="USD">
                USD
              </Option>
              <Option key="UZS" value="UZS">
                UZS
              </Option>
            </Select>
          </div>
        </div>

        <div className="mt-10 flex flex-col items-end gap-5 sm:flex-row">
          <div className="flex w-full flex-col">
            {items.map((item, index) => (
              <div
                key={index}
                className="mt-1 flex flex-col items-center gap-5 sm:flex-row"
              >
                <div className="flex w-full flex-col gap-3 sm:w-auto">
                  {index === 0 ? <p>{t("item")}</p> : ""}
                  <Spin spinning={loadingData.fdata}>
                    <Select
                      value={
                        item.itemCode
                          ? fdata.find((f) => f.code === item.itemCode)?.name
                          : undefined
                      }
                      placeholder={t("choose")}
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      className="h-12 w-full items-center justify-center sm:w-[350px]"
                      onChange={(value) => handleSelectChange(value, index)}
                    >
                      {fdata
                        .filter((f) => f.name !== null)
                        .map((f) => (
                          <Select.Option key={f.code} value={f.name}>
                            {f.name}
                          </Select.Option>
                        ))}
                    </Select>
                  </Spin>
                </div>

                <div className="flex w-full flex-col gap-3 sm:w-auto">
                  {index === 0 ? <p>{t("price")}</p> : ""}
                  <Input
                    type="text"
                    value={item.price}
                    placeholder={t("price")}
                    onChange={(e) => handleInputChange(e, index, "price")}
                    className="h-12 w-full items-center justify-center border-[1px] border-[#D0D0D0] sm:w-[100px]"
                  />
                </div>

                <div className="flex w-full flex-col gap-3 sm:w-auto">
                  {index === 0 ? <p>{t("quantity")}</p> : ""}
                  <Input
                    value={item.quantity}
                    onChange={(e) => handleInputChange(e, index, "quantity")}
                    type="text"
                    placeholder={t("quantity")}
                    className={`h-12 w-full items-center justify-center border-[1px] border-[#D0D0D0] sm:w-[80px] ${index !== 0 ? "ml-3" : ""}`}
                  />
                </div>

                <div className="flex w-full flex-col gap-3 sm:w-auto">
                  {index === 0 ? <p>{t("totalPrice")}</p> : ""}
                  <Input
                    value={Number(item.quantity) * Number(item.price)}
                    style={{ color: "black" }}
                    disabled
                    className={`h-12 w-full items-center justify-center border-[1px] border-[#D0D0D0] sm:w-[100px] `}
                  />
                </div>
                <div className="flex w-full flex-col gap-3 sm:w-auto">
                  {index === 0 ? <p>{t("currency")}</p> : ""}
                  <Input
                    value={item.currency}
                    style={{ color: "black" }}
                    className="h-12 w-full items-center justify-center sm:w-[80px]"
                    disabled
                  />
                </div>
                {index !== 0 ? (
                  <button
                    onClick={() => removeItem(index)}
                    className={`mt-2 h-7 w-[30px] border-2 border-black bg-[#0A4D68] text-red-500 sm:mt-1"`}
                  >
                    -
                  </button>
                ) : (
                  ""
                )}

              </div>
            ))}
          </div>

          <div>
            <button
              onClick={addItem}
              className="mb-2 h-7 w-[30px] items-center border-2 border-black bg-[#0A4D68] text-white"
            >
              +
            </button>
          </div>
        </div>

        <div className="mt-10 flex flex-col items-center justify-between sm:flex-row">
          <div className="flex w-full flex-col gap-3 sm:w-auto">
            <p>{t("whs")}</p>
            <Spin spinning={loadingData.wdata}>
              <Select
                value={
                  warehouseCode
                    ? wdata.find((item) => item.code === warehouseCode)?.name
                    : undefined
                }
                placeholder={t("choose")}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
                onChange={handleOmborChange}
                className="h-12 w-full items-center justify-center sm:w-[250px]"
              >
                {wdata
                  .filter((item) => item.name !== null)
                  .map((item) => (
                    <Select.Option key={item.code} value={item.name}>
                      {item.name}
                    </Select.Option>
                  ))}
              </Select>
            </Spin>
          </div>

          <div className="flex w-full flex-col gap-3 sm:w-auto">
            <p>{t("driver")}</p>
            <Spin spinning={loadingData.ddata}>
              <Select
                value={
                  driverCode
                    ? ddata.find((item) => item.value === driverCode)?.name
                    : undefined
                }
                placeholder={t("chooseDriver")}
                onChange={handleDriverChange}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
                className="flex h-12 w-full items-center justify-center sm:w-[250px]"
              >
                {ddata
                  .filter((item) => item.name !== null)
                  .map((item) => (
                    <Select.Option key={item.value} value={item.name}>
                      {item.name}
                    </Select.Option>
                  ))}
              </Select>
            </Spin>
          </div>
        </div>

        <div className="mt-24">
          <Button
            onClick={postData}
            className="h-12 w-full rounded-3xl bg-[#0A4D68] text-white sm:w-[350px]"
            disabled={loading}
            loading={loading}
          >
            {loading ? t("creating") : t("create")}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Buy;
