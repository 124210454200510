import React from "react";
import { Modal } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import PostStock from '../../pages/whrManager/ombor/inProccess/createStock';
import { useTranslation } from 'react-i18next';

const PostStockModal = ({ visible,  onClose }) => {
const {t} = useTranslation()
  return (
    <Modal
      title={
        <h1 className="font-nunito text-xl font-extrabold text-[#000000]">
          {t("order")} № 3
        </h1>
      }
      visible={visible}
      onCancel={onClose}
      footer={null}
      closeIcon={<CloseOutlined />}
      width="70%"
      style={{ height: "70%" }}
      bodyStyle={{ height: "calc(100% - 55px)", overflowY: "auto" }}
    >
      <PostStock onClose={onClose} visible={visible} />
    </Modal>
  );
};

export default PostStockModal;
