import React from 'react';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';
import { useTranslation } from 'react-i18next';

const TotalRevenueChart = () => {
  const {t} = useTranslation()
  const data = {
    labels: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
    datasets: [
      {
        label: 'Online Sales',
        data: [12000, 15000, 10000, 17000, 14000, 19000, 13000],
        backgroundColor: 'rgba(59, 130, 246, 0.6)',
      },
      {
        label: 'Offline Sales',
        data: [8000, 10000, 15000, 9000, 11000, 10000, 7000],
        backgroundColor: 'rgba(16, 185, 129, 0.6)',
      },
    ],
  };

  const options = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  return (
    <div className="max-w-4xl mx-auto p-4 bg-white rounded-lg shadow-md">
      <h2 className="text-xl font-bold mb-4">{t("totalRevenue")}</h2>
      <Bar data={data} options={options} />
      <div className="flex justify-center mt-4 space-x-4">
        <div className="flex items-center">
          <span className="inline-block w-3 h-3 bg-blue-500 rounded-full mr-2"></span>
          Online Sales
        </div>
        <div className="flex items-center">
          <span className="inline-block w-3 h-3 bg-green-500 rounded-full mr-2"></span>
          Offline Sales
        </div>
      </div>
    </div>
  );
};

export default TotalRevenueChart;
