import axios from "axios";

import { config, CUSTOM_API_ROOT_PROD, CUSTOM_API_ROOT_TEST } from "../config";

import { session } from "./session";

export const http = axios.create({
  baseURL:
    window.location.hostname.includes("krupa-snab-test") ||
    window.location.hostname.includes("localhost")
      ? CUSTOM_API_ROOT_TEST
      : CUSTOM_API_ROOT_PROD,
});

http.interceptors.request.use((request) => {
  const token = session.get();

  if (token) {
    request.headers = {
      ...request.headers,
      [config.backendTokenKEY]: `Bearer ${token}`,
    };
  }

  return request;
});
