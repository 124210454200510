import { IoDocumentTextOutline } from "react-icons/io5";
import { GiNetworkBars } from "react-icons/gi";
import { CiShoppingTag } from "react-icons/ci";
import { Menubar as MenuWhs } from "../components/WhrManager/App";
import Navbar from "../components/navbar";
import LineChart from "../components/dashboard/lineChart";
import TotalRevenueChart from "../components/dashboard/totalrevenue";
import TopProductsTable from "../components/dashboard/topProducts";
import { useTranslation } from "react-i18next";
import { http } from "../services/http";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Menubar as MenuSales } from "../components/SalesManager/App";

function Dashboard() {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const role = useSelector((state) => state.main.role);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await http.get("/api/dashboard/sotuvlar");
      setData(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="flex w-full">
      {role === "Whsmanager" ? <MenuWhs /> : <MenuSales />}
      <div className="h-screen w-full overflow-y-auto">
        <Navbar />

        <div className="flex items-center gap-10">
          <div className="ml-5 mt-6 flex h-[350px] w-[60%] flex-col justify-around rounded-2xl bg-white drop-shadow-xl">
            <div className="ml-3 flex items-center justify-between">
              <div className="flex flex-col items-center gap-2">
                <h1 className="font-poppins text-xl font-semibold text-[#05004E]">
                  {t("Sales")}
                </h1>
              </div>

              <div></div>
            </div>
            <div className="flex items-center justify-around gap-3 p-5">
              <div className="flex h-[180px] w-[150px] flex-col gap-2 rounded-3xl bg-[#FFE2E5]">
                <div className="ml-4 mt-5 flex h-10 w-10 items-center justify-center rounded-full bg-[#FA5A7D]">
                  <GiNetworkBars className="text-white" />
                </div>
                <h1 className="font-poppins ml-4 text-xl font-bold text-[#151D48]">
                  $ {data.count} k
                </h1>
                <h2 className="font-poppins ml-4 text-sm font-bold text-[#425166]">
                  {t("totalSales")}
                </h2>
                <p className="font-poppins ml-4 text-sm text-[#4079ED]">
                  {data.countsChange >= 0
                    ? `+${data.countsChange}% ${t("fromYesterday")}`
                    : `${data.countsChange}% ${t("fromYesterday")}`}
                </p>
              </div>

              <div className="flex h-[180px] w-[150px] flex-col gap-2 rounded-3xl bg-[#FFF4DE]">
                <div className="ml-4 mt-5 flex h-10 w-10 items-center justify-center rounded-full bg-[#FA5A7D]">
                  <IoDocumentTextOutline className="text-white" />
                </div>
                <h1 className="font-poppins ml-4 text-xl font-bold text-[#151D48]">
                  {data.docTotal}
                </h1>
                <h2 className="font-poppins ml-4 text-sm font-medium text-[#425166]">
                  {t("orders")}
                </h2>
                <p className="font-poppins ml-4 text-sm text-[#4079ED]">
                  {data.docTotalChange >= 0
                    ? `+${data.docTotalChange}% ${t("fromYesterday")}`
                    : `${data.docTotalChange}% ${t("fromYesterday")}`}
                </p>
              </div>

              <div className="flex h-[180px] w-[150px] flex-col gap-2 rounded-3xl bg-[#DCFCE7]">
                <div className="ml-4 mt-5 flex h-10 w-10 items-center justify-center rounded-full bg-[#3CD856]">
                  <CiShoppingTag className="text-white" />
                </div>
                <h1 className="font-poppins ml-6 text-xl font-bold text-[#151D48]">
                  {data.quantity}
                </h1>
                <h2 className="font-poppins ml-4 text-sm font-medium text-[#425166]">
                  {t("pSold")}
                </h2>
                <p className="font-poppins ml-4 text-sm text-[#4079ED]">
                  {data.quantityChange >= 0
                    ? `+${data.quantityChange}% ${t("fromYesterday")}`
                    : `${data.quantityChange}% ${t("fromYesterday")}`}
                </p>
              </div>
            </div>
          </div>

          <div className="mt-6 flex w-[35%] items-center justify-center rounded-2xl bg-gray-100">
            <LineChart />
          </div>
        </div>

        <div className="mb-10 mt-10 flex items-center justify-between">
          <div className="ml-6 w-[40%] rounded-2xl bg-white drop-shadow-xl">
            <TotalRevenueChart />
          </div>


        </div>

        <div className="mt-10 flex items-center justify-between">
          <div className="ml-6 h-max w-[40%] rounded-2xl bg-white drop-shadow-xl">
            <TopProductsTable />
          </div>


        </div>
      </div>
    </div>
  );
}

export default Dashboard;
