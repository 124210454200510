import { Modal, Table,Input } from "antd";
import React, { useRef, useState,useEffect } from "react";
import { useTranslation } from "react-i18next";
import { CloseOutlined } from "@ant-design/icons";
import {  formattedClient } from "../../utils/document";
import { http } from '../../services/http';
import {getToday} from "../../utils/getDays"

const ClientsModal = ({ visible, datas, onClose }) => {

 const today = getToday()

  const { t } = useTranslation();
  const pdfRef = useRef(null);

  const [data, setData] = useState([]);


  const [startDate, setStartDate] = useState("2024-01-01");
  const [endDate, setEndDate] = useState(today);

  const columns = [
    {
      title: t("date"),
      dataIndex: "dueDate",
      key: "dueDate",
    },
    {
      title: t("numClient"),
      dataIndex: "docNum",
      key: "docNum",
      render: (text) => {
        const formattedText = new Intl.NumberFormat("fr-FR").format(text);
        return `${formattedText}`;
      },
    },
    {
      title: t("lineMemo"),
      dataIndex: "lineMemo",
      key: "lineMemo",
    },

    {
      title: "Debit",
      dataIndex: "debit",
      key: "debit",
      render: (text) => {
        const formattedText = new Intl.NumberFormat("fr-FR").format(text);
        return `${formattedText}`;
      },
    },
    {
      title: "Credit",
      dataIndex: "credit",
      key: "credit",
      render: (text) => {
        const formattedText = new Intl.NumberFormat("fr-FR").format(text);
        return `${formattedText}`;
      },
    },
    {
      title: t("balanceClient"),
      dataIndex: "cumulativeBalance",
      key: "cumulativeBalance",
      render: (text) => {
        const formattedText = new Intl.NumberFormat("fr-FR").format(text);
        return `${formattedText}`;
      },
    },
  ];

  useEffect(() => {
    fetchClientModal(datas,startDate,endDate);
  }, [ datas,startDate, endDate]);


  const fetchClientModal = async (cardCode,startDate,endDate) => {
    try {
      const { data } = await http.get(
        `api/businesspartners/get-bp-info-by-cardcode?cardCode=${cardCode}&startDate=${startDate}&endDate=${endDate}`,
      );

      const formatClient = formattedClient(data);
      setData(formatClient);
    } catch (error) {
      setData([]);
      console.error("Error fetching data:", error);
    }
  };

  const formattedData =
    data?.transactions?.map((line, index) => ({
      key: index,
      cardName: data.cardName,
      cumulativeBalance: line.cumulativeBalance,
      debit: line.debit,
      credit: line.credit,
      lineMemo: line.memo,
      docNum: line.docNum,
      dueDate: line.dueDate,
    })) || [];

    const balance = new Intl.NumberFormat("fr-FR").format(data?.balance );
    const balanceFirstDayOfPeriod = new Intl.NumberFormat("fr-FR").format(data?.balanceFirstDayOfPeriod );

  return (
    <Modal
      title={
        <h1 className="font-nunito text-xl font-extrabold text-[#000000]">
          {t("order")} № {data?.docNum || ""}
        </h1>
      }
      visible={visible}
      onCancel={onClose}
      footer={null}
      closeIcon={<CloseOutlined />}
      width="85%"
      style={{ height: "85%" }}
      bodyStyle={{ height: "calc(100% - 55px)", overflowY: "auto" }}
    >
      <div className="w-full px-14" ref={pdfRef}>

      <div className="mt-4 flex flex-col justify-between sm:mt-10 sm:flex-row">
            <div className="flex flex-col gap-3">
              <p className="font-nunito">{t("mijoz")}</p>
              <Input
                type="text"
                style={{ color: "black" }}
                value={data?.cardName}
                className="flex h-12 w-full items-center justify-center sm:w-[250px]"
                disabled
              />
            </div>
            <div className="ml-10 mt-5 flex gap-5">
          <div className="flex flex-col gap-3">
            <p>{t("startDate")}</p>
            <Input
              type="date"
              value={startDate}
              className="w-[150px]"
              onChange={(e) => {
                setStartDate(e.target.value);
              }}
              max={endDate}
            />
          </div>
          <div className="flex flex-col gap-3">
            <p>{t("endDate")}</p>
            <Input
              type="date"
              value={endDate}
              className="w-[150px]"
              onChange={(e) => {
                setEndDate(e.target.value);
              }}
              min={startDate}
              max={today}
            />
          </div>
        </div>
        </div>

        <div className="mt-4 flex flex-col justify-between sm:mt-10 sm:flex-row">
            <div className="flex flex-col gap-3">
              <p className="font-nunito">{t("balance")}</p>
              <Input
                type="text"
                style={{ color: "black" }}
                value={balance}
                className="flex h-12 w-full items-center justify-center sm:w-[250px]"
                disabled
              />
            </div>
            <div className="flex flex-col gap-3">
              <p className="font-nunito">{t("balanceFirstDayOfPeriod")}</p>
              <Input
                type="text"
                style={{ color: "black" }}
                value={balanceFirstDayOfPeriod}
                className="flex h-12 w-full items-center justify-center sm:w-[250px]"
                disabled
              />
            </div>
        </div>

        <Table
          columns={columns}
          dataSource={formattedData}
          pagination={false}
          className="border-1 mt-12 border-black"
        />
      </div>
    </Modal>
  );
};

export default ClientsModal;
