import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
import { useTranslation } from 'react-i18next';
import { http } from '../../services/http';

const LineChart = () => {
  const { t } = useTranslation();
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: 'Last Month',
        data: [],
        fill: true,
        backgroundColor: 'rgba(59, 130, 246, 0.2)',
        borderColor: 'rgba(59, 130, 246, 1)',
      },
      {
        label: 'This Month',
        data: [],
        fill: true,
        backgroundColor: 'rgba(34, 197, 94, 0.2)',
        borderColor: 'rgba(34, 197, 94, 1)',
      },
    ],
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await http.get("api/dashboard/get-by-period");

        const extractData = (dataArray) => {
          const labels = [];
          const values = [];
          dataArray.forEach(item => {
            labels.push(item.docDate);
            values.push(item.docTotal);
          });
          return { labels, values };
        };

        const thisMonthData = extractData(data.thisMonth);
        const lastMonthData = extractData(data.lastMonth);

        setChartData({
          labels: Array.from(new Set([...lastMonthData.labels, ...thisMonthData.labels])), // Merge and deduplicate labels
          datasets: [
            {
              label: 'Last Month',
              data: lastMonthData.values,
              fill: true,
              backgroundColor: 'rgba(59, 130, 246, 0.2)',
              borderColor: 'rgba(59, 130, 246, 1)',
            },
            {
              label: 'This Month',
              data: thisMonthData.values,
              fill: true,
              backgroundColor: 'rgba(34, 197, 94, 0.2)',
              borderColor: 'rgba(34, 197, 94, 1)',
            },
          ],
        });
      } catch (err) {
        console.log(err);
      }
    };

    fetchData();
  }, []);

  const options = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  return (
    <div className="h-[350px] w-[100%] mx-auto p-4 bg-white rounded-lg shadow-md">
      <h2 className="text-xl font-bold mb-4">{t("custumerSat")}</h2>
      <Line data={chartData} options={options} />
      <div className="flex justify-between mt-8">
        <div>
          <span className="inline-block w-3 h-3 bg-blue-500 rounded-full mr-2"></span>
          Last Month: ${chartData.datasets[0].data.reduce((a, b) => a + b, 0)}
        </div>
        <div>
          <span className="inline-block w-3 h-3 bg-green-500 rounded-full mr-2"></span>
          This Month: ${chartData.datasets[1].data.reduce((a, b) => a + b, 0)}
        </div>
      </div>
    </div>
  );
};

export default LineChart;
