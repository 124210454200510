const today = new Date();

export const  getToday = () => {
const day = ("0" + today.getDate()).slice(-2);
const month = ("0" + (today.getMonth() + 1)).slice(-2);
const year = today.getFullYear();
const formattedDate = `${year}-${month}-${day}`;
return formattedDate
}

export const getFiveDaysAgo = () => {
  const fiveDaysAgo = new Date();
fiveDaysAgo.setDate(today.getDate() - 5);
const dayFiveAgo = ("0" + fiveDaysAgo.getDate()).slice(-2);
const monthFiveAgo = ("0" + (fiveDaysAgo.getMonth() + 1)).slice(-2);
const yearFiveAgo = fiveDaysAgo.getFullYear();
const minDate = `${yearFiveAgo}-${monthFiveAgo}-${dayFiveAgo}`;
return minDate
}