import { Modal, Input, Button, Select, Table, message } from "antd";
import React, { useState, useEffect, useCallback, useMemo } from "react";
import { http } from "../../services/http";
import { useTranslation } from "react-i18next";
import { CloseOutlined } from "@ant-design/icons";

const { Option } = Select;

const today = new Date();
const day = ("0" + today.getDate()).slice(-2);
const month = ("0" + (today.getMonth() + 1)).slice(-2);
const year = today.getFullYear();
const formattedDate = `${year}-${month}-${day}`;

const fiveDaysAgo = new Date();
fiveDaysAgo.setDate(today.getDate() - 5);
const dayFiveAgo = ("0" + fiveDaysAgo.getDate()).slice(-2);
const monthFiveAgo = ("0" + (fiveDaysAgo.getMonth() + 1)).slice(-2);
const yearFiveAgo = fiveDaysAgo.getFullYear();
const minDate = `${yearFiveAgo}-${monthFiveAgo}-${dayFiveAgo}`;

const RowDataShipmentEdit = ({ visible, data, onClose }) => {
  const { t } = useTranslation();
  const [tableData, setTableData] = useState([]);
  const [fData, setFData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [wdata, setwData] = useState([]);
  const [currency, setCurrency] = useState(data.currency[0] || "");
  const [slp, setSlp] = useState(data.salesPersonCode || "");
  const [date, setDate] = useState(formattedDate);

  const fetchGroup = async () => {
    try {
      const { data } = await http.get(`api/salesemployees`);
      const mappedItems = data.map((item) => ({
        code: item.slpCode,
        name: item.slpName,
      }));
      setwData(mappedItems);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchGroup();
  }, []);

  const handleGroupChange = (value) => {
    const selectedItem = wdata.find((item) => item.name === value);
    setSlp(selectedItem ? selectedItem.code : "");
  };

  useEffect(() => {
    if (data) {
      const {
        maxsulot,
        itemCode,
        currency,
        quantity,
        measureUnit,
        price,
        inventoryQuantity,
        lineTotal,
        whsCode,
        lineNum,
        lineStatus
      } = data;
      setTableData(
        maxsulot.map((item, index) => ({
          key: index,
          maxsulot: item,
          itemCode: itemCode ? itemCode[index] : "",
          currency: currency ? currency[index] : "USD",
          quantity: quantity ? quantity[index] : "",
          measureUnit: measureUnit ? measureUnit[index] : "",
          price: price ? price[index] : "",
          inventoryQuantity: inventoryQuantity ? inventoryQuantity[index] : "",
          lineTotal: lineTotal ? lineTotal[index] : "",
          whsCode: whsCode ? whsCode[index] : "",
          lineStatus: lineStatus ? lineStatus[index] : "",
          lineNum: lineNum? lineNum[index] : "",
        })),
      );
    }
  }, [data]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const { data } = await http.get(`api/items`);
      const mappedItems = data.map((item) => ({
        code: item.itemCode,
        name: item.itemName,
        measureUnit: item.salesUnit,
        price: item.itemPrice.price,
        currency:
          item.itemPrice.currency !== null ? item.itemPrice.currency : 0,
        priceList: item.itemPrice.priceList,
      }));
      setFData(mappedItems);
    } catch (error) {
      console.log(error);
    }
  };

  const handleCurrencyChange = (value) => {
    setCurrency(value);
    setTableData((prevData) =>
      prevData.map((item) => ({
        ...item,
        currency: value,
        price: "",
      })),
    );
    message.info(t("inputPrice", { value }));
  };

  const handleMaxsulotChange = useCallback(
    (value, key) => {
      const selectedItem = fData.find((item) => item.name === value);
      if (selectedItem) {
        const { code, price, measureUnit } = selectedItem;
        setTableData((prevData) =>
          prevData.map((item) =>
            item.key === key
              ? {
                  ...item,
                  maxsulot: value,
                  itemCode: code,
                  price: price,
                  measureUnit: measureUnit,
                }
              : item,
          ),
        );
      } else {
        console.log("Selected item is undefined.");
      }
    },
    [fData],
  );

  const handleQuantityChange = useCallback((e, key) => {
    const newQuantity = e.target.value;
    setTableData((prevData) =>
      prevData.map((item) =>
        item.key === key ? { ...item, quantity: newQuantity } : item,
      ),
    );
  }, []);

  const handlePriceChange = useCallback((e, key) => {
    const newPrice = e.target.value;
    setTableData((prevData) =>
      prevData.map((item) =>
        item.key === key ? { ...item, price: newPrice } : item,
      ),
    );
  }, []);

  const handleDeleteRow = useCallback(
    (key) => {
      if (tableData.length > 1) {
        setTableData((prevData) => prevData.filter((item) => item.key !== key));
      } else {
        message.warning(t("At least one row must be present."));
      }
    },
    [tableData, t]
  );


  const handleAddRow = () => {
    const newRow = {
      key: tableData.length,
      maxsulot: "",
      itemCode: "",
      currency: "USD",
      quantity: "",
      measureUnit: "",
      price: "",
      inventoryQuantity: "",
      lineTotal: "",
      whsCode: data.whsCode[0],
      lineStatus: "",
      lineNum: tableData.length + 1,
    };
    setTableData((prevData) => [...prevData, newRow]);
  };

  const columns = useMemo(
    () => [
      {
        title: t("item"),
        dataIndex: "maxsulot",
        key: "maxsulot",
        render: (text, record) => (
          <Select
            value={text}
            showSearch
            optionFilterProp="children"
            className="w-[380px]"
            onChange={(value) => handleMaxsulotChange(value, record.key)}
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
            disabled={record.lineStatus === "C"}
          >
            {fData.map((item) => (
              <Option key={item.code} value={item.name}>
                {item.name}
              </Option>
            ))}
          </Select>
        ),
      },
      {
        title: t("quantity"),
        dataIndex: "quantity",
        key: "quantity",
        render: (text, record) => (
          <Input
            value={text}
            className="w-[80px]"
            type="text"
            onChange={(e) => handleQuantityChange(e, record.key)}
            disabled={record.lineStatus === "C"}
          />
        ),
      },
      {
        title: t("measureUnit"),
        dataIndex: "measureUnit",
        key: "measureUnit",
        render: (text, record,index) => {
          return <span className={`${data.lineStatus[index] === "C" ? "text-slate-500" :""}`}>{text}</span>;
        },
      },
      {
        title: t("price"),
        dataIndex: "price",
        key: "price",
        render: (text, record) => (
          <Input
            className="w-[100px]"
            type="text"
            value={text}
            onChange={(e) => handlePriceChange(e, record.key)}
            disabled={record.lineStatus === "C"}
          />
        ),
      },

      // {
      //   title: t("totalMiqdor"),
      //   dataIndex: "lineTotal",
      //   key: "lineTotal",
      //   render: (text, record) => {
      //     const formattedText = new Intl.NumberFormat("fr-FR").format(text);
      //     return `${formattedText} ${data.currency[0]}`;
      //   },
      // },
      {
        title: "",
        key: "actions",
        render: (_, record) => (
          <Button
            // type="link"
            onClick={() => handleDeleteRow(record.key)}
            disabled={tableData.length === 1}
          >
            -
          </Button>
        ),
      },
    ],
    [
      fData,
      handleDeleteRow,
      handleMaxsulotChange,
      handlePriceChange,
      handleQuantityChange,
      t,
      tableData.length,
    ],
  );

  const patchData = useCallback(async () => {
    // const hasEmptyFields = tableData.some(
    //   (item) =>
    //     !item.itemCode ||
    //     !item.quantity ||
    //     !item.price ||
    //     !item.currency ||
    //     !item.measureUnit,
    // );

    // if (hasEmptyFields) {
    //   message.error(t("Please fill in all required fields."));
    //   return;
    // }

    const documentLines = tableData
    .filter((item) => item.lineStatus !== "C")
    .map((item, index) => ({
      lineNum: item.lineNum,
      itemCode: item.itemCode,
      quantity: Number(item.quantity),
      unitPrice: Number(item.price),
      currency: item.currency,
      warehouseCode: item.whsCode,
      measureUnit: item.measureUnit,
    }));

    setLoading(true);

    try {
      const res = await http.patch(`api/salesorder`, {
        docEntry: data.docEntry,
        salesPersonCode: slp,
        docDueDate: date,
        documentLines,
      });
      message.success(t("sucCreated"));
      setTimeout(() => {
        onClose();
        window.location.reload();
      }, 800);
    } catch (err) {
      message.error(t("errCreation") + err.message);
    } finally {
      setLoading(false);
    }
  }, [tableData, data.docEntry, t, slp, currency, onClose]);

  return (
    <Modal
      title={
        <h1 className="font-nunito text-xl font-extrabold text-[#000000]">
          {t("order")} № {data.docNum}
        </h1>
      }
      visible={visible}
      onCancel={onClose}
      footer={null}
      closeIcon={<CloseOutlined />}
      width="85%"
      style={{ height: "85%" }}
      bodyStyle={{ height: "calc(100% - 55px)", overflowY: "auto" }}
    >
      <div className="w-full px-14">
        <div className="w-full">
          <div className="mt-10 flex items-center justify-between">
            <div className="flex flex-col gap-3">
              <p className="font-nunito">{t("mijoz")}</p>
              <Input
                type="text"
                value={data.mijoz}
                className="flex h-12 w-[250px] items-center justify-center"
                style={{ color: "black" }}
                disabled
              />
            </div>

            <div className="flex w-full flex-col gap-3 sm:w-auto">
              <p className="font-nunito">{t("currency")}</p>
              <Select
                value={currency}
                placeholder={t("choose")}
                showSearch
                optionFilterProp="children"
                onChange={handleCurrencyChange}
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
                className="flex h-12 w-full items-center justify-center sm:w-[250px]"
              >
                <Option key="USD" value="USD">
                  USD
                </Option>
                <Option key="UZS" value="UZS">
                  UZS
                </Option>
              </Select>
            </div>

            <div className="flex flex-col gap-3">
              <p className="font-nunito">{t("date")} </p>
              <Input
                type="date"
                value={date}
                className="w-[150px]"
                onChange={(e) => {
                  setDate(e.target.value);
                }}
                max={formattedDate}
                min={minDate}
              />
            </div>
          </div>
          <Table
            columns={columns}
            dataSource={tableData}
            pagination={false}
            className="border-1 mt-12 border-black"
          />
          <div className="mt-4 flex justify-start">
            <Button type="primary" onClick={handleAddRow}>
              +
            </Button>
          </div>

          <div className="mt-10 flex items-center justify-between">
            <div className="flex flex-col gap-3">
              <p>{t("whs")}</p>
              <Input
                defaultValue={data.whsName[0]}
                className="flex h-12 w-[250px] items-center justify-center"
                style={{ color: "black" }}
                disabled
              />
            </div>
            <div className="mt-10 flex items-center justify-between">
              <div className="flex w-full flex-col gap-5 sm:w-auto">
                <div className="flex w-full flex-col gap-3 sm:w-auto">
                  <p>{t("responsiblePerson")}</p>
                  <Select
                    value={wdata.find((item) => item.code === slp)?.name}
                    placeholder={t("choose")}
                    showSearch
                    optionFilterProp="children"
                    onChange={handleGroupChange}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    className="flex h-12 w-full items-center justify-center sm:w-[250px]"
                  >
                    {wdata
                      .filter((item) => item.name !== null)
                      .map((item) => (
                        <Option key={item.code} value={item.name}>
                          {item.name}
                        </Option>
                      ))}
                  </Select>
                </div>
              </div>
            </div>
          </div>

          <div className="flex h-[100%] w-[100%] flex-col justify-center">
            <div className="mt-20 flex items-center justify-between">
              <Button
                onClick={patchData}
                disabled={loading}
                loading={loading}
                className="h-12 w-[250px] rounded-3xl bg-[#0A4D68] text-white"
              >
                {t("save")}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default RowDataShipmentEdit;

