import React from "react";
import { Modal, Table } from "antd";
import { CloseOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

const RowDataOmborModal = ({ visible, data, onClose }) => {
  const { t } = useTranslation()
  if (!data) return null;

  const columns = [
    {
      title: t("item"),
      dataIndex: "itemDescription",
      key: "itemDescription",
    },
    {
      title: t("quantity"),
      dataIndex: "quantity",
      key: "quantity",
      render: (text, record) => {
        const formattedText = new Intl.NumberFormat("fr-FR").format(
          text
        );
        return `${formattedText}`;
      },
    }
  ];

  const tableData = data.itemDescription.map((item, index) => ({
    key: index,
    itemDescription: item,
    quantity: data.quantity[index]
  }));

  return (
    <Modal
      title={<h1 className="font-nunito text-xl font-extrabold text-[#000000]">{t("order")} № {data.docEntry}</h1>}
      visible={visible}
      onCancel={onClose}
      footer={null}
      closeIcon={<CloseOutlined />}
      width="70%"
      bodyStyle={{ maxHeight: "80vh", overflowY: "auto" }}
    >
      <div className="w-full px-4 sm:px-14">
        <Table
          columns={columns}
          dataSource={tableData}
          pagination={false}
          className="mt-4 border-1 border-black w-full"
          scroll={{ x: "100%" }}

        />
        {/* <div className="mt-4 sm:mt-10 flex flex-col sm:flex-row justify-between">
          <div className="flex flex-col gap-3">
            <p>{t("from-whs")}</p>
            <Select
              defaultValue={data.fromWarehouseCode}
              className="flex h-12 w-full sm:w-[250px] items-center justify-center"
              disabled
            />
          </div>

          <div className="flex flex-col gap-3 mt-4 sm:mt-0">
            <p>{t("toWhs")}</p>
            <Select
              defaultValue={data.warehouseCode}
              className="flex h-12 w-full sm:w-[250px] items-center justify-center"
              disabled
            />
          </div>
        </div> */}
      </div>
    </Modal>
  );
};

export default RowDataOmborModal;
