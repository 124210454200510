import React, { useState, useEffect } from "react";
import { Select, Input, message, Spin,Button } from "antd";
import { http } from "../../../services/http";
import { useTranslation } from "react-i18next";

function CreateClient({ onCancel, visible }) {
  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const { t } = useTranslation();
  const [data, setData] = useState({
    cardCode: "",
    cardName: "",
    phoneNum: "",
    type: "",
    currCode: "",
    groupCode :""
  });
  const[wdata,setwData] = useState([])

  const fetchGroup = async () => {
    setLoadingData(true)
    try {
      const { data } = await http.get(`api/businesspartners/bp-groups`);
      const mappedItems = data.map((item) => ({
        code: item.groupCode,
        name: item.groupName,
      }));
      setwData(mappedItems);
    } catch (err) {
      console.log(err);
    }
    finally{
    setLoadingData(false)
    }
  };

  useEffect(() => {
    fetchGroup();
  }, []);

  const handleGroupChange = (value) => {
    const selectedItem = wdata.find((item) => item.name === value);
    setData((prevState) => ({
      ...prevState,
      groupCode: selectedItem.code,
    }));
  };


  const { cardName, phoneNum,groupCode,cardCode } = data;

  useEffect(() => {
    if (visible) {
      resetForm();
    }
  }, [visible]);

  const postData = async () => {
    if (cardName && phoneNum) {
      setLoading(true);
      try {
        await http.post("api/businesspartners", {
          cardCode,
          cardName,
          cardType: "C",
          phone1: phoneNum,
          currency: "##",
          groupCode
        });
        message.success(t("sucCreated"));

        setTimeout(() => {
          resetForm();
          onCancel();
        }, 800);
      } catch (error) {
        message.error(t("errCreation") + error.message);
        console.log("Error:", error);
      } finally {
        setLoading(false);
      }
    } else {
      message.error(t("fillForm"));
    }
  };

  const resetForm = () => {
    setData((prevState) => ({
      ...prevState,
      cardCode: "",
      cardName: "",
      phoneNum: "",
    }));
  };

  return (
    <div className="w-full px-4 sm:px-14">
      <div className="w-full">


    <div className="mt-6 sm:mt-7 flex flex-col gap-5">

    <div className="flex flex-col gap-3">
            <p>{t("cardCode")}</p>
            <Input
              onChange={(e) => {
                setData((prevState) => ({
                  ...prevState,
                  cardCode: e.target.value,
                }));
              }}
              value={cardCode}
              placeholder={t("inputCardCode")}
              className="flex h-12 w-full sm:w-[250px] items-center justify-center border-[1px] border-[#D0D0D0]"
            />
          </div>


          <div className="flex flex-col gap-3">
            <p>{t("cardName")}</p>
            <Input
              onChange={(e) => {
                setData((prevState) => ({
                  ...prevState,
                  cardName: e.target.value,
                }));
              }}
              value={cardName}
              placeholder={t("inputCardName")}
              className="flex h-12 w-full sm:w-[250px] items-center justify-center border-[1px] border-[#D0D0D0]"
            />
          </div>

          <div className="flex flex-col gap-3">
            <p>{t("phoneNum")}</p>
            <Input
              value={phoneNum}
              onChange={(e) => {
                setData((prevState) => ({
                  ...prevState,
                  phoneNum: e.target.value,
                }));
              }}
              placeholder={t("inputPhoneNum")}
              className="flex h-12 w-full sm:w-[250px] items-center justify-center border-[1px] border-[#D0D0D0]"
            />
          </div>
        </div>

        <div className="flex flex-col gap-3 mt-5 w-full sm:w-auto">
            <p className="font-nunito">{t("groupCode")}</p>
            <Spin spinning={loadingData}>
              <Select
                value={groupCode ? wdata.find(item => item.code === groupCode)?.name : undefined}
                placeholder={t("choose")}
                showSearch
            optionFilterProp="children"
                onChange={handleGroupChange}
                filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
                className="flex h-12 w-full sm:w-[250px] items-center justify-center"
              >
                {wdata
                  .filter((item) => item.name !== null)
                  .map((item) => (
                    <Select.Option key={item.code} value={item.name}>
                      {item.name}
                    </Select.Option>
                  ))}
              </Select>
            </Spin>
          </div>


        <div className="mt-8 sm:mt-20">
          <Spin spinning={loading}>
            <Button
              onClick={postData}
              className="h-12 w-full sm:w-[350px] rounded-3xl bg-[#0A4D68] text-white"
              disabled={loading}
              loading={loading}
            >
              {loading ? t("saving") : t("saveAndCreate")}
            </Button>
          </Spin>
        </div>
      </div>
    </div>
  );
}

export default CreateClient;




