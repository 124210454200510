import React from "react";
import { Modal } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import CreateSale from '../../pages/salesManager/sales/createSale';
import { useTranslation } from 'react-i18next';

const CreateSaleModal = ({ visible,  onClose }) => {
const {t} = useTranslation()
  return (
    <Modal
      title={
        <h1 className="font-nunito text-xl font-extrabold text-[#000000]">
          {t("order")} № 3
        </h1>
      }
      visible={visible}
      onCancel={onClose}
      footer={null}
      closeIcon={<CloseOutlined />}
      width="80%"
      style={{ height: "80%" }}
      bodyStyle={{ height: "calc(100% - 55px)", overflowY: "auto" }}
    >
      <CreateSale onClose={onClose} visible={visible}/>
    </Modal>
  );
};

export default CreateSaleModal;
