import { Modal, Input, Table, Select } from "antd";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { CloseOutlined } from "@ant-design/icons";

const calculateDifferences = (openQty, quantity) => {
  return openQty.map((openQtyValue, index) => {
    return quantity[index] - openQtyValue;
  });
};

const RowDataShipmentModal = ({ visible, data, onClose }) => {
  const { t } = useTranslation();
  const initialData = data || {
    openQty: [],
    quantity: [],
  };
  const [uQuantity, setUQuantity] = useState(
    calculateDifferences(initialData.openQty, initialData.quantity),
  );

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedComments, setSelectedComments] = useState("");

  const handleSelectClick = () => {
    setSelectedComments(data.comments);
    setIsModalVisible(true);
  };

  const handleModalOk = () => {
    setIsModalVisible(false);
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    if (data && data.openQty && data.quantity) {
      const differences = calculateDifferences(data.openQty, data.quantity);
      setUQuantity(differences);
    }
  }, [data]);

  //  useEffect(() => {
  //     if (data) {
  //       setUQuantity(data.openQty || []);

  //     }
  //   }, [data]);

  if (!data) return null;

  const sum =
  data.docCur === "UZS"
    ? new Intl.NumberFormat("fr-FR").format(data.docTotalFC)
    : new Intl.NumberFormat("fr-FR").format(data.summa);

  const columns = [
    {
      title: t("item"),
      dataIndex: "maxsulot",
      key: "maxsulot",
    },
    {
      title: t("quantityShipped"),
      dataIndex: "quantity",
      key: "quantity",
      render: (text, record) => {
        const formattedText = new Intl.NumberFormat("fr-FR").format(text);
        return `${formattedText} `;
      },
    },
    {
      title: t("quantityRemoved"),
      dataIndex: "uQuantity",
      key: "uQuantity",
      render: (text, record) => {
        const formattedText = new Intl.NumberFormat("fr-FR").format(text);
        return `${formattedText} `;
      },
    },
    {
      title: t("openQty"),
      dataIndex: "openQty",
      key: "openQty",
      render: (text, record) => {
        const formattedText = new Intl.NumberFormat("fr-FR").format(text);
        return `${formattedText} `;
      },
    },
    {
      title: t("unitMsr"),
      dataIndex: "measureUnit",
      key: "measureUnit",
    },
    {
      title: t("price"),
      dataIndex: "price",
      key: "price",
      render: (text, record) => {
        const formattedText = new Intl.NumberFormat("fr-FR").format(text);
        return `${formattedText} ${record.currency}`;
      },
    },
    {
      title: t("inventoryQuantity"),
      dataIndex: "openInvQty",
      key: "openInvQty",
    },
    {
      title: t("ugpName"),
      dataIndex: "ugpName",
      key: "ugpName",
    },
    {
      title: t("totalMiqdor"),
      dataIndex: "lineTotal",
      key: "lineTotal",
      render: (text, record) => {
        const formatSum =
        record.currency === "UZS"
          ? new Intl.NumberFormat("fr-FR").format(record.totalFrgn)
          : new Intl.NumberFormat("fr-FR").format(text);
      return `${formatSum} ${record.currency}`;
      },
    },
    {
      title: t("whs"),
      dataIndex: "whsName",
      key: "whsName",
    },
  ];

  const tableData = data.maxsulot.map((item, index) => ({
    key: index,
    maxsulot: item,
    quantity: data.quantity[index],
    measureUnit: data.measureUnit[index],
    price: data.price[index],
    uQuantity: uQuantity[index],
    lineTotal: data.lineTotal[index],
    openQty: data.openQty[index],
    openInvQty: data.openInvQty[index],
    ugpName: data.ugpName[index],
    whsName: data.whsName[index],
    currency: data.currency[index],
    totalFrgn: data.totalFrgn[index],
  }));

  return (
    <Modal
      title={
        <h1 className="font-nunito text-xl font-extrabold text-[#000000]">
          {t("order")} № {data.docNum}
        </h1>
      }
      visible={visible}
      onCancel={onClose}
      footer={null}
      closeIcon={<CloseOutlined />}
      width="70%"
      style={{ height: "70%" }}
      bodyStyle={{ height: "calc(100% - 55px)", overflowY: "auto" }}
    >
      <div className="w-full px-4 sm:px-14">
        <div className="w-full">
          <div className="mt-10 flex flex-col items-center justify-between sm:flex-row">
            <div className="flex flex-col gap-3">
              <p className="font-nunito">{t("mijoz")}</p>
              <Input
                type="text"
                value={data.mijoz}
                className="flex h-12 w-[200px] items-center justify-center sm:w-[250px]"
                style={{ color: "black" }}
                disabled
              />
            </div>
            <div className="mt-4 flex flex-col gap-3 sm:mt-0">
              <p className="font-nunito">{t("date")}</p>
              <Input value={data.sana} style={{ color: "black" }} disabled />
            </div>
          </div>
          <Table
            columns={columns}
            dataSource={tableData}
            pagination={false}
            className="border-1 mt-12 border-black"
            scroll={{ x: "100%" }}
          />

          <div className="mt-10 flex items-center justify-between">
          <div className="flex flex-col gap-3">
              <p>{t("comments")}</p>
              <Select
                value={data.comments}
                className="flex h-12 w-full items-center justify-center sm:w-[250px]"
                style={{ color: "black" }}
                disabled
                onClick={handleSelectClick}
              />
              <Modal
                title={t("comments")}
                visible={isModalVisible}
                onOk={handleModalOk}
                onCancel={handleModalCancel}
              >
                <p>{selectedComments}</p>
              </Modal>
            </div>
            <div className="flex items-center justify-end gap-5">
              <div className="flex flex-col gap-3">
                <p>{t("docTotalQuantity")}</p>
                <Input
                  type="text"
                  value={`${data.docTotalQuantity} кг`}
                  style={{ color: "black" }}
                  className="flex h-10 w-full items-center justify-center border-[1px] border-[#D0D0D0] active:border-[#D0D0D0] sm:w-[200px]"
                  disabled
                />
              </div>

              <div className="flex flex-col gap-3">
                <p>{t("totalPrice")}</p>
                <Input
                  type="text"
                  value={`${sum} ${data.docCur}`}
                  style={{ color: "black" }}
                  className="flex h-10 w-full items-center justify-center border-[1px] border-[#D0D0D0] active:border-[#D0D0D0] sm:w-[200px]"
                  disabled
                />
              </div>
            </div>
          </div>
          <div className="mt-10 flex flex-col items-center justify-between gap-3 sm:flex-row">

            <div className="mt-4 flex flex-col gap-3 sm:mt-0">
              <p>{t("driver")}</p>
              <Input
                type="text"
                value={data.driver}
                disabled
                style={{ color: "black" }}
                className="flex h-10 w-[180px] items-center justify-center border-[1px] border-[#D0D0D0]"
              />
            </div>
            <div className="mt-4 flex flex-col gap-3 sm:mt-0">
              <p>{t("responsiblePerson")}</p>
              <Input
                type="text"
                value={data.yaratdi}
                style={{ color: "black" }}
                disabled
                className="flex h-10 w-[200px] items-center justify-center border-[1px] border-[#D0D0D0]"
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default RowDataShipmentModal;
