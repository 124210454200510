import React from "react";
import { Modal } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import Buy from '../../pages/whrManager/purchasement/buy';
import { useTranslation } from 'react-i18next';

const BuyModal = ({ visible,  onClose }) => {
const {t}  =useTranslation()
  return (
    <Modal
      title={
        <h1 className="font-nunito text-xl font-extrabold text-[#000000]">
         {t("order")}  № 3
        </h1>
      }
      visible={visible}
      onCancel={onClose}
      footer={null}
      closeIcon={<CloseOutlined />}
      width="80%"
      style={{ height: "80%" }}
      bodyStyle={{ height: "calc(100% - 55px)", overflowY: "auto" }}
    >
      <Buy  onClose={onClose} visible={visible}/>
    </Modal>
  );
};

export default BuyModal;
