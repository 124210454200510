import React, { useState, useCallback, useEffect, useMemo } from "react";
import { Table, Select, Input } from "antd";
import Menubar from "../../components/SalesManager/App";
import { http } from "../../services/http";
import { useTranslation } from "react-i18next";
import Navbar from "../../components/navbar";
import { formattedDataDebtor, calculateTotals } from "../../utils/document";
import debounce from "lodash.debounce";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";

const { Option } = Select;

const Debtors = () => {
  const [fdata, setFData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totals, setTotals] = useState({});

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const { t } = useTranslation();
  const [slp, setSlp] = useState([]);
  const [region, setRegion] = useState([]);

  const [selectedRegion, setSelectedRegion] = useState("");
  const [selectedSlp, setSelectedSlp] = useState("");
  const [selectedName, setSelectedName] = useState("");

  let searchInput = null;

  const fetchData = useCallback(async (selectedName, selectedSlp, bpRegion) => {
    try {
      setLoading(true);

      const { data } = await http.get(
        `api/businesspartners/debetors?bpName=${selectedName}&slpName=${selectedSlp}&region=${bpRegion}`,
      );
      const newSlp = new Set(slp);
      const newRegion = new Set(region);
      const uniqueEntries = new Set();

      setTotals(calculateTotals(data));

      const formattedData = formattedDataDebtor(data);
      setFData(formattedData);

      data.filter((item) => {
        const key = `${item.salesPerson}-${item.region}`;

        if (uniqueEntries.has(key)) {
          return false;
        }

        uniqueEntries.add(key);
        newSlp.add(item.salesPerson);
        newRegion.add(item.region);

        return true;
      });

      setSlp([...newSlp]);
      setRegion([...newRegion]);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  }, []);

  const handleGroupChange = (value, key) => {
    switch (key) {
      case "itemGroup":
        value !== undefined ? setSelectedSlp(value) : setSelectedSlp("");
        break;
      case "region":
        value !== undefined ? setSelectedRegion(value) : setSelectedRegion("");
        break;
      default:
        break;
    }
  };

  const debouncedSearch = useMemo(
    () =>
      debounce((value, dataIndex, confirm) => {
        setSearchText(value);
        setSearchedColumn(dataIndex);
        confirm();
      }, 1000),
    [],
  );

  const handleSearch = (e, confirm, dataIndex) => {
    const value = e.target.value;
    setSelectedName(value);
    debouncedSearch(value, dataIndex, confirm);
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={`${t("search")} ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
            handleSearch(e, confirm, dataIndex);
          }}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  useEffect(() => {
    fetchData(selectedName, selectedSlp, selectedRegion);
  }, [fetchData, selectedName, selectedSlp, selectedRegion]);

  // const handleScroll = (event) => {
  //   const { scrollTop, clientHeight, scrollHeight } = event.target;
  //   if (scrollTop + clientHeight >= scrollHeight && hasMoreData && !loading) {
  //     setCurrentPage((prevPage) => prevPage + 1);
  //   }
  // }
  const columns = [
    {
      title: t("region"),
      dataIndex: "region",
      key: "region",
      filterDropdown: () => (
        <Select
          allowClear
          style={{ width: 200 }}
          placeholder="Select Region"
          value={selectedRegion}
          onChange={(value) => handleGroupChange(value, "region")}
        >
          {region.map((desc) => (
            <Select.Option key={desc} value={desc}>
              {desc}
            </Select.Option>
          ))}
        </Select>
      ),
      onFilter: (value, record) => record.region === value,
    },
    {
      title: t("realizators"),
      dataIndex: "realizators",
      key: "realizators",
      ...getColumnSearchProps("realizators"),
      className: "bg-blue-100",
    },
    {
      title: t("balanceFirstDayOfTheMonth"),
      dataIndex: "balanceFirstDayOfTheMonth",
      key: "balanceFirstDayOfTheMonth",
      render: (text) => {
        const formattedText = new Intl.NumberFormat("fr-FR").format(text);
        return `${formattedText}`;
      },
      sorter: (a, b) =>
        a.balanceFirstDayOfTheMonth - b.balanceFirstDayOfTheMonth,
    },
    {
      title: t("purchasedProduct"),
      dataIndex: "purchasedProduct",
      key: "purchasedProduct",
      render: (text) => {
        const formattedText = new Intl.NumberFormat("fr-FR").format(text);
        return `${formattedText}`;
      },
      sorter: (a, b) => a.purchasedProduct - b.purchasedProduct,
    },
    {
      title: t("paidMoney"),
      dataIndex: "paidMoney",
      key: "paidMoney",
      render: (text) => {
        const formattedText = new Intl.NumberFormat("fr-FR").format(text);
        return `${formattedText}`;
      },
      sorter: (a, b) => a.paidMoney - b.paidMoney,
    },
    {
      title: t("balance"),
      dataIndex: "balance",
      key: "balance",
      render: (text) => {
        const formattedText = new Intl.NumberFormat("fr-FR").format(text);
        return `${formattedText}`;
      },
      sorter: (a, b) => a.balance - b.balance,
    },
    {
      title: t("moneySpeed"),
      dataIndex: "moneySpeed",
      key: "moneySpeed",
      className: "bg-blue-100",
      render: (text) => {
        const formattedText = new Intl.NumberFormat("fr-FR").format(text);
        return `${formattedText}`;
      },
      sorter: (a, b) => a.moneySpeed - b.moneySpeed,
    },
    {
      title: t("salesPerson"),
      dataIndex: "salesPerson",
      key: "salesPerson",
      filterDropdown: () => (
        <Select
          allowClear
          style={{ width: 200 }}
          placeholder="Select Sales Person"
          value={selectedSlp}
          onChange={(value) => handleGroupChange(value, "itemGroup")}
        >
          {slp.map((name) => (
            <Option key={name} value={name}>
              {name}
            </Option>
          ))}
        </Select>
      ),
      onFilter: (value, record) => record.salesPerson === value,
    },
    {
      title: t("balanceLastDayOfTheMonth"),
      dataIndex: "balanceLastDayOfTheMonth",
      key: "balanceLastDayOfTheMonth",
      render: (text) => {
        const formattedText = new Intl.NumberFormat("fr-FR").format(text);
        return `${formattedText}`;
      },
      sorter: (a, b) => a.balanceLastDayOfTheMonth - b.balanceLastDayOfTheMonth,
    },
    {
      title: t("totalAmountReceived"),
      dataIndex: "totalAmountReceived",
      key: "totalAmountReceived",
      render: (text) => {
        const formattedText = new Intl.NumberFormat("fr-FR").format(text);
        return `${formattedText}`;
      },
      sorter: (a, b) => a.totalAmountReceived - b.totalAmountReceived,
    },
  ];

  return (
    <div className="flex h-full w-full overflow-y-auto">
      <Menubar />
      <div className="h-screen w-full overflow-y-auto">
        <Navbar />
        <h1 className="font-poppins ml-4 mt-10 text-xl font-bold text-black sm:ml-10 sm:mt-14 sm:text-2xl">
          {t("debtors")}
        </h1>
        <div className="mt-10 w-full border-[1px] border-[#E8E8E8] sm:mt-14"></div>
        {/* <div className="flex flex-col justify-end mt-5 gap-5 ml-10  w-full sm:w-auto">
                  <p>{t("debt")}</p>
                  <Input
                    value={debt}
                    disabled
                    className="h-12 w-full sm:w-[180px] items-center justify-center border-[1px] border-[#D0D0D0]"
                  />
         </div> */}
        <div className="mt-5 p-4">
          <Table
            columns={columns}
            dataSource={fdata}
            pagination={false}
            loading={loading}
            sticky={true}
            summary={() => (
              <Table.Summary.Row>
                <Table.Summary.Cell
                  index={1}
                  colSpan={2}
                  className="font-bold text-black"
                >
                  {t("total")}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={2} className="font-bold text-black">
                  {new Intl.NumberFormat("fr-FR").format(
                    totals.balanceFirstDayOfTheMonth,
                  )}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={3} className="font-bold text-black">
                  {new Intl.NumberFormat("fr-FR").format(
                    totals.purchasedProduct,
                  )}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={4} className="font-bold text-black">
                  {new Intl.NumberFormat("fr-FR").format(totals.paidMoney)}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={5} className="font-bold text-black">
                  {new Intl.NumberFormat("fr-FR").format(totals.balance)}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={6} className="font-bold text-black">
                  {new Intl.NumberFormat("fr-FR").format(totals.moneySpeed)}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={7}></Table.Summary.Cell>
                <Table.Summary.Cell index={8} className="font-bold text-black">
                  {new Intl.NumberFormat("fr-FR").format(
                    totals.balanceLastDayOfTheMonth,
                  )}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={9} className="font-bold text-black">
                  {new Intl.NumberFormat("fr-FR").format(
                    totals.totalAmountReceived,
                  )}
                </Table.Summary.Cell>
              </Table.Summary.Row>
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default Debtors;
