import { Modal, Input, Table, Button, Space, message } from "antd";
import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { CloseOutlined } from "@ant-design/icons";
import { http } from "../../services/http";

const today = new Date();
const day = ("0" + today.getDate()).slice(-2);
const month = ("0" + (today.getMonth() + 1)).slice(-2);
const year = today.getFullYear();
const formattedDate = `${year}-${month}-${day}`;

const RemoveItemModal = ({ visible, data, onClose }) => {
  const { t } = useTranslation();
  const initialData = data || {
    cardCode: "",
    docEntry: "",
    openQty: [],
    maxsulot: [],
    itemCode: [],
    quantity: [],
    measureUnit: [],
    price: [],
    inventoryQuantity: [],
    lineTotal: [],
    lineNum: [],
  };

  const fiveDaysAgo = new Date();
fiveDaysAgo.setDate(today.getDate() - 5);
const dayFiveAgo = ("0" + fiveDaysAgo.getDate()).slice(-2);
const monthFiveAgo = ("0" + (fiveDaysAgo.getMonth() + 1)).slice(-2);
const yearFiveAgo = fiveDaysAgo.getFullYear();
const minDate = `${yearFiveAgo}-${monthFiveAgo}-${dayFiveAgo}`;

  const [loading, setLoading] = useState(false);
  const [uQuantity, setUQuantity] = useState(initialData.openQty);
  const [openQuantity, setOpenQuantity] = useState(initialData.openQty);
  const [tableData, setTableData] = useState([]);
  const [date, setDate] = useState(formattedDate);

  useEffect(() => {
    if (data) {
      setUQuantity(data.openQty || []);
      setOpenQuantity(data.openQty || []);
      setTableData(
        data.maxsulot.map((item, index) => ({
          key: index,
          maxsulot: item,
          quantity: data.quantity[index],
          openQty: data.openQty[index],
          measureUnit: data.measureUnit[index],
          uQuantity: data.openQty[index],
          price: data.price[index],
          inventoryQuantity: data.inventoryQuantity[index],
          lineTotal: data.lineTotal[index],
        })),
      );
    }
  }, [data]);

  const postData = async () => {
    setLoading(true);

    try {
      const documentLines = tableData.map((item, index) => {
        if (item.uQuantity !== 0 && item.uQuantity <= item.openQty) {
          return {
            itemCode: initialData.itemCode[index],
            quantity: Number(item.uQuantity),
            baseEntry: initialData.docEntry,
            baseType: 15,
            baseLine: initialData.lineNum[index],
          };
        } else {
          return null;
        }
      });

      const invalidItems = documentLines.filter((line) => line === null);
      if (invalidItems.length > 0) {
        message.info(
          "Qaytariladigan miqdor 0 dan katta va sotuvga o'tadigan miqdordan kichik bo'lishi kerak!",
        );
        setLoading(false);
        return;
      }

      // Filter out null values
      const validDocumentLines = documentLines.filter((line) => line !== null);

      await http.post("api/return", {
        cardCode: data.cardCode,
        docDueDate: date,

        documentLines: validDocumentLines,
      });
      message.success(t("sucCreated"));

      setTimeout(() => {
        onClose();
        // window.location.reload()
      }, 800);
    } catch (error) {
      message.error(t("errCreation") + (error?.message || ""));
    } finally {
      setLoading(false);
    }
  };

  const handleQuantityChange = useCallback(
    (e, index) => {
      const newQuantity = [...uQuantity];
      newQuantity[index] = e.target.value;
      setUQuantity(newQuantity);

      const newTableData = [...tableData];
      newTableData[index].uQuantity = e.target.value;
      setTableData(newTableData);
    },
    [uQuantity, tableData],
  );

  const handleDeleteRow = (index) => {
    setTableData((prevTableData) =>
      prevTableData.filter((_, i) => i !== index),
    );
  };

  const formattedSumma = new Intl.NumberFormat("fr-FR").format(data?.summa);

  const columns = [
    {
      title: t("item"),
      dataIndex: "maxsulot",
      key: "maxsulot",
    },
    {
      title: t("quantityShipped"),
      dataIndex: "quantity",
      key: "quantity",
      render: (text) => {
        const formattedText = new Intl.NumberFormat("fr-FR").format(text);
        return `${formattedText}`;
      },
    },
    {
      title: t("quantityRemove"),
      dataIndex: "uQuantity",
      key: "uQuantity",
      render: (text, record, index) => (
        <Input
          type="number"
          value={uQuantity[index]}
          min={1}
          max={Number(openQuantity[index])}
          onChange={(e) => handleQuantityChange(e, index)}
        />
      ),
    },
    {
      title: t("openQty"),
      dataIndex: "openQty",
      key: "openQty",
      render: (text) => {
        const formattedText = new Intl.NumberFormat("fr-FR").format(text);
        return `${formattedText}`;
      },
    },
    {
      title: t("unitMsr"),
      dataIndex: "measureUnit",
      key: "measureUnit",
    },
    {
      title: t("price"),
      dataIndex: "price",
      key: "price",
      render: (text) => {
        const formattedText = new Intl.NumberFormat("fr-FR").format(text);
        return `${formattedText} ${data.currency[0]}`;
      },
    },
    {
      title: t("inventoryQuantity"),
      dataIndex: "inventoryQuantity",
      key: "inventoryQuantity",
    },
    {
      title: t("totalMiqdor"),
      dataIndex: "lineTotal",
      key: "lineTotal",
      render: (text) => {
        const formattedText = new Intl.NumberFormat("fr-FR").format(text);
        return `${formattedText} ${data.currency[0]}`;
      },
    },
    {
      title: "",
      key: "action",
      render: (text, record, index) => (
        <Space size="middle">
          <Button onClick={() => handleDeleteRow(index)}> - </Button>
        </Space>
      ),
    },
  ];

  return (
    <Modal
      title={
        <h1 className="font-nunito text-xl font-extrabold text-[#000000]">
          {t("order")} № {data?.docNum}
        </h1>
      }
      visible={visible}
      onCancel={onClose}
      footer={null}
      closeIcon={<CloseOutlined />}
      width="80%"
      style={{ height: "80%" }}
      bodyStyle={{ height: "calc(100% - 55px)", overflowY: "auto" }}
    >
      <div className="w-full px-4 sm:px-14">
        <div className="w-full">
          <div className="mt-10 flex flex-col items-center justify-between sm:flex-row">
            <div className="flex flex-col gap-3">
              <p className="font-nunito">{t("mijoz")}</p>
              <Input
                type="text"
                value={data?.mijoz}
                className="flex h-12 w-[200px] items-center justify-center sm:w-[250px]"
                style={{ color: "black" }}
                disabled
              />
            </div>
            <div className="mt-4 flex flex-col gap-3 sm:mt-0">
              <p className="font-nunito">{t("date")}</p>
              <Input
                type="date"
                value={date}
                className="w-[150px]"
                onChange={(e) => {
                  setDate(e.target.value);
                }}
                max={formattedDate}
                min={minDate}
              />
            </div>
          </div>
          <Table
            columns={columns}
            dataSource={tableData}
            pagination={false}
            className="border-1 mt-12 border-black"
            scroll={{ x: "100%" }}
          />
          <div className="mt-24">
            <Button
              onClick={postData}
              className="h-12 w-full rounded-3xl bg-[#0A4D68] text-white sm:w-[350px]"
              disabled={loading}
              loading={loading}
            >
              {t("remove")}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default RemoveItemModal;
